import { useParams } from 'react-router-dom';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';
import { MessageProviderType, MessagesProvider } from 'contexts/useMessagesContext';
import { PlayRecordingProvider } from 'contexts/usePlayRecordingIndex';

import { ProcessOrderProvider } from 'features/order/contexts/useProcessOrderContext';
import { OrderProvider } from 'features/order/contexts/useOrderContext';
import { MessageByIdPage } from '../pages';

const MessageByIdScreen = () => {
  const { messageId } = useParams();

  return (
    <ProtectedScreen>
      <MessagesProvider
        providerType={MessageProviderType.BY_MESSAGE_ID}
        messageId={messageId}
      >
        <PlayRecordingProvider>
          <OrderProvider>
            <ProcessOrderProvider onOrderDraftProcessed={null}>
              <MessageByIdPage />
            </ProcessOrderProvider>
          </OrderProvider>
        </PlayRecordingProvider>
      </MessagesProvider>
    </ProtectedScreen>
  );
};

export default MessageByIdScreen;
