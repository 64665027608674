import {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { LoadingOverlay } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import { Order } from 'features/order/models/Order';
import { useOrderContext } from 'features/order/contexts/useOrderContext';
import { globalUser } from 'state/globalUser';
import { Business } from 'models/Business';
import { useFetchCustomerById } from 'hooks/fetch/useFetchCustomerById';
import { useMessagesContext } from 'contexts/useMessagesContext';
import { ManageCustomerSidebarWrapper } from 'components/wrapper/ManageCustomerSidebarWrapper';
import { useBusinessSettingsContext } from 'contexts/useBusinessSettingsContext';
import { useFetchBusinessSettings } from 'hooks/fetch/useFetchBusinessSettings';
import { useSchemasContext } from 'contexts/useSchemasContext';
import { TypeSpec } from 'models/Schema';
import { useFetchSchemaByTypeRef } from 'hooks/fetch/useFetchSchemaByTypeRef';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { OrderMessages } from './OrderMessages';
import { OrderDraftPanel } from './OrderDraftPanel';

const ProcessNewOrderDraft = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get('customer_id');

  const { order, setOrder } = useProcessOrderContext();
  const { groupOrders, setGroupOrders } = useOrderContext();
  const { loadCustomer } = useFetchCustomerById();
  const { messages, isLoading: isMessageLoading } = useMessagesContext();
  const { schemas, setSchemas } = useSchemasContext();

  const { setBusinessSettings } = useBusinessSettingsContext();

  const { businessSettings, isLoading: isBusinessSettingsLoading } = useFetchBusinessSettings({});
  const { loadSchema: loadOrderSchema, isLoading: isOrderSchemaLoading } = useFetchSchemaByTypeRef();

  const [isOrderDraftCreating, setIsOrderDraftCreating] = useState(false);

  const manageCustomerModalWrapperRef = useRef(null);

  const [typeSpecs, setTypeSpecs] = useState<TypeSpec[]>([]);

  const onManageCustomerButtonClick = useCallback(() => {
    if (manageCustomerModalWrapperRef.current) {
      manageCustomerModalWrapperRef.current.onManageCustomerButtonClick();
    }
  }, []);

  const onCustomerReconciliationDone = useCallback(
    (customer: Business) => {
      setOrder((order_) => ({
        ...order_,
        customer,
      }));
    },
    [setOrder],
  );

  useEffect(() => {
    setIsOrderDraftCreating(true);
    const newOrder = new Order();
    newOrder.assigneeId = globalUser.id;
    if (customerId) {
      const business = new Business({ name: '' });
      business.id = customerId;
    }
    newOrder.isCreated = true;

    setGroupOrders([newOrder]);
    setIsOrderDraftCreating(false);
  }, [customerId, setGroupOrders]);

  useEffect(() => {
    if (typeSpecs) {
      typeSpecs?.forEach((typeSpec) => {
        if (schemas[typeSpec.typeRef]) {
          return;
        }

        loadOrderSchema(typeSpec.typeRef)
          .then((schema) => {
            setSchemas((_schemas) => ({ ..._schemas, [typeSpec.typeRef]: schema }));
          });
      });
    }
  }, [loadOrderSchema, setSchemas, typeSpecs, schemas]);

  useEffect(() => {
    setTypeSpecs(order?.typeSpecs);
  }, [order?.typeSpecs]);

  useEffect(() => {
    if (customerId) {
      loadCustomer(customerId, false).then(
        (response) => setOrder((order_) => ({
          ...order_,
          customer: response,
        })),
      );
    }
  }, [customerId, loadCustomer, setOrder]);

  useEffect(() => {
    setBusinessSettings(businessSettings);
  }, [businessSettings, setBusinessSettings]);

  return (
    <ManageCustomerSidebarWrapper
      ref={manageCustomerModalWrapperRef}
      customer={order?.customer}
      onComplete={onCustomerReconciliationDone}
    >
      <div className="flex h-full flex-1 overflow-hidden">
        <div className="max-[50%] relative h-full min-w-[50%] overflow-hidden border-r border-blue-gray-50 bg-white">
          <LoadingOverlay
            visible={isMessageLoading}
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
          <OrderMessages
            messages={messages}
            customer={groupOrders[0]?.customer}
            onManageCustomerButtonClick={onManageCustomerButtonClick}
          />
        </div>
        <div className="max-[50%] relative h-full min-w-[50%]">
          <LoadingOverlay
            visible={isOrderDraftCreating || isBusinessSettingsLoading || isOrderSchemaLoading}
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
          <OrderDraftPanel />
        </div>
      </div>
    </ManageCustomerSidebarWrapper>
  );
};

export default ProcessNewOrderDraft;
