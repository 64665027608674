import { memo, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mantine/core';

import {
  getFieldByPath, getValueByPath, inputTypeToUniversalFieldType,
} from 'helpers/schema';

import { UniversalField } from 'components/common/UniversalField';
import { FieldSpec, Schema } from 'models/Schema';

interface Props {
  fieldSpec: FieldSpec;
  schema: Schema;
  obj: any;
  setError?: (key: string, error: string) => void;
  updateValueByPath: (value: any, path: string) => void;
}

const CustomerField = ({
  fieldSpec, schema, obj, setError, updateValueByPath,
}: Props) => {
  const field = getFieldByPath(schema?.fields, fieldSpec.path);

  const value = getValueByPath(obj, fieldSpec.modelPath);
  const extractedValue = getValueByPath(obj, fieldSpec.modelAutoMatchedPath);
  const matches = useMemo(() => extractedValue === value, [extractedValue, value]);

  const RightSection = useMemo(() => {
    if (!extractedValue) {
      return null;
    }

    return (
      matches ? (
        <FontAwesomeIcon icon={faCheck} className="h-4 w-4 text-green-500" />
      ) : (
        <Tooltip label="Extracted value and current value do not match">
          <FontAwesomeIcon icon={faExclamationTriangle} className="h-4 w-4 text-warning-500" />
        </Tooltip>
      )
    );
  }, [extractedValue, matches]);

  const setErrorByPath = useCallback((error: string) => {
    setError?.(fieldSpec.path, error);
  }, [setError, fieldSpec.path]);

  const setValueByPath = useCallback((val: any) => {
    updateValueByPath(val, fieldSpec.modelPath);
  }, [updateValueByPath, fieldSpec.modelPath]);

  return (
    <div key={fieldSpec.path} className="grid grid-cols-2 gap-4 py-2">
      <div>
        <UniversalField
          type={inputTypeToUniversalFieldType(field?.inputType, field?.type)}
          validation={fieldSpec.validation}
          label={fieldSpec.name}
          value={value}
          shouldScroll={false}
          setError={setErrorByPath}
          onValueChange={setValueByPath}
        />
      </div>
      <div>
        <UniversalField
          type={inputTypeToUniversalFieldType(field?.inputType, field?.type)}
          validation={fieldSpec.validation}
          label={`${fieldSpec.name} (Extracted)`}
          value={extractedValue}
          shouldScroll={false}
          setError={setErrorByPath}
          rightSection={RightSection}
        />
      </div>
    </div>
  );
};

export default memo(CustomerField);
