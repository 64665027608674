/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { object } from 'yup';
import { Formik } from 'formik';

import { Page } from 'components/layout/Page/Page';
import { BluePinkyBackground } from 'components/background/BluePinkyBackground';
import { ThreeDots } from 'components/ThreeDots';
import { FormInput } from 'components/FormInput';
import { ROUTES } from 'config/routes';
import {
  password as passwordValidation,
  username as usernameValidation,
} from 'helpers/validations';
import { globalUser } from 'state/globalUser';
import { UserSigninParams } from 'state/classes/User';
import { genericErrorFeedback } from 'helpers/errors';

import logo from 'assets/logo/hoshii.svg';

interface SigninFormValues {
  username: string;
  password: string;
}

const formInitialValues = {
  username: '',
  password: '',
};

const formValidationSchema = object({
  username: usernameValidation().required(),
  password: passwordValidation().required(),
});

type Props = {
  username?: string;
  navigateToHome: () => void;
};

/* eslint-disable jsx-a11y/label-has-associated-control */
const SigninPage = observer(({ username, navigateToHome }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitialValues);

  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    inputRef.current?.focus();

    const handleKeyDown = (event: KeyboardEvent) => {
      if (buttonRef.current && event.key === 'Enter') {
        buttonRef.current?.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (username) {
      setInitialValues({
        username,
        password: '',
      });
    }
  }, [username]);

  if (globalUser.isSignedIn) {
    return <Navigate to={ROUTES.HOME} />;
  }

  return (
    <Page isLoading={isLoading} hideMenu hideHeader>
      <div className="flex flex-1 items-start justify-center p-lg">
        <div className="relative flex h-full w-full items-center justify-center space-y-5">
          <BluePinkyBackground />

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[30%] 3xl:max-w-[25%] 4xl:max-w-[20%]">
            <div className="space-y-3 bg-white px-6 py-12 shadow-md sm:rounded-lg sm:px-12">
              <div className="mb-lg flex w-full items-center justify-center">
                <img
                  className="relative mr-2 h-[2.5rem] w-auto sm:h-[3rem]"
                  src={logo}
                  alt=""
                  style={{
                    filter:
                      'brightness(0) saturate(100%) invert(12%) sepia(18%) saturate(5372%) hue-rotate(193deg) brightness(97%) contrast(96%)',
                  }}
                />
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={formValidationSchema}
                onSubmit={(values: SigninFormValues, { setFieldValue }) => {
                  setIsLoading(true);
                  globalUser
                    .signIn(values as UserSigninParams)
                    .then(() => {
                      navigateToHome();
                    })
                    .catch(genericErrorFeedback('Error during signin'))
                    .finally(() => {
                      setIsLoading(false);
                      // set password to empty
                      setFieldValue('password', '');
                    });
                }}
                enableReinitialize
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                }) => (
                  <div className="space-y-6">
                    <FormInput
                      ref={inputRef}
                      field="username"
                      required
                      disabled={isLoading}
                      label="Username"
                      value={values.username}
                      error={errors.username}
                      touched={touched.username}
                      onChange={handleChange('username')}
                      onBlur={handleBlur('username')}
                    />
                    <FormInput
                      field="password"
                      type="password"
                      required
                      disabled={isLoading}
                      label="Password"
                      value={values.password}
                      error={errors.password}
                      touched={touched.password}
                      onChange={handleChange('password')}
                      onBlur={handleBlur('password')}
                    />

                    <div>
                      <button
                        type="button"
                        onClick={() => handleSubmit()}
                        ref={buttonRef}
                        className="flex w-full justify-center rounded-md bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                      >
                        <span>
                          {isLoading ? (
                            <div className="flex max-h-[24px] min-h-[24px] flex-col items-center justify-center">
                              <ThreeDots />
                            </div>
                          ) : (
                            'Sign in'
                          )}
                        </span>
                      </button>
                    </div>
                    <div className="flex justify-center">
                      <div className="text-sm/6">
                        <a
                          href={ROUTES.SEND_RESET_PASSWORD_LINK}
                          className="font-semibold text-indigo-600 hover:text-indigo-500"
                        >
                          Forgot password?
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            </div>

            <p className="mt-10 text-center text-sm text-gray-500">
              Having issues signing in?
              {' '}
              <a
                href="https://hoshii.atlassian.net/servicedesk/customer/portal/1"
                className="font-semibold leading-6 text-primary-500 hover:text-indigo-500"
              >
                Customer Support
              </a>
            </p>
          </div>
        </div>
      </div>
    </Page>
  );
});

export default SigninPage;
