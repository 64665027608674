import {
  useCallback, useRef, useState,
} from 'react';
import { Accordion } from '@mantine/core';

import { ProductWithQuantity } from 'features/order/models/Order';
import { FieldSpec, Schema } from 'models/Schema';
import { useDragDropContext } from 'contexts/useDragDropContext';

import RenderIfVisible from 'components/ui/RenderIfVisible';
import LoadingOverlay from 'components/ui/LoadingOverlay';
import { ProductSortingColumn } from 'types/product';
import { isZeroId } from 'helpers/objectId';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { getValueByPath, setValueByPath } from 'helpers/schema';
import { PRODUCTS_MODEL_PATH } from 'constants/prompt';
import Control from './ProductFIeldControl';
import Panel from './ProductFieldPanel/ProductFieldPanel';

interface Props {
  index: number;
  product: ProductWithQuantity;
  customerId: string;

  fieldSpecs: FieldSpec[];
  schema: Schema;

  value: string;

  setError?: (key: string, error: string) => void;

  // Filter related fields
  // TODO: move to context
  sortingColumn: ProductSortingColumn;
  filterEnabledProducts: boolean;
}

const PlaceHolderElement = ({ id, dataName, productName }: { id: string, dataName: string, productName: string }) => (
  <div id={id} data-name={dataName} className="h-[200px] flex flex-col rounded-md border border-gray-200">
    <div className="px-10 py-2">{productName}</div>
    <div className="flex-1 relative">
      <LoadingOverlay visible />
    </div>
  </div>
);

const ProductField = ({
  index,
  value,
  product: globalProduct,
  customerId,
  fieldSpecs,
  schema,
  sortingColumn,
  filterEnabledProducts,
  setError: setError_,
}: Props) => {
  const { updateProductByIndex, onConfirmProduct } = useProcessOrderContext();
  // NOTE: localProduct is a copy of globalProduct, whenever you update localProduct, remember to call updateProductByIndex
  const [localProduct, setLocalProduct] = useState<ProductWithQuantity>(globalProduct);

  const { triggerHoverEffect } = useProcessOrderContext();
  const { setDraggedItemId, setDragging } = useDragDropContext();

  const accordionItemRef = useRef<HTMLDivElement>(null);
  const dragImageRef = useRef(null);

  const id = localProduct?.positionId && !isZeroId(localProduct?.positionId)
    ? localProduct.positionId
    : localProduct?.uiId || localProduct?.name?.trim();

  const dataName = localProduct?.name?.trim();

  const onMouseEnter = () => {
    // TODO: change to positionId
    triggerHoverEffect(localProduct?.positionId || localProduct?.name);
  };

  const onMouseLeave = () => {
    triggerHoverEffect('');
  };

  // Drag and drop
  const createDragImage = (node: HTMLElement) => {
    const dragImage = node.cloneNode(true) as HTMLElement;
    dragImage.style.position = 'absolute';
    dragImage.style.top = '-9999px';
    dragImage.style.left = '-9999px';
    dragImage.style.transform = 'scale(0.4)';
    document.body.appendChild(dragImage);
    dragImageRef.current = dragImage;
  };

  const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    if (accordionItemRef.current) {
      createDragImage(accordionItemRef.current);
      e.dataTransfer.setDragImage(dragImageRef.current!, 0, 0);
    }
    setDraggedItemId(localProduct.uiId);
    setDragging(true);
  };

  const onDragEnd = () => {
    setDragging(false);
  };

  const updateLocalProduct = useCallback((values: any) => {
    const newLocalProduct = { ...localProduct, ...values };
    setLocalProduct(newLocalProduct);

    if (newLocalProduct) {
      updateProductByIndex(index, newLocalProduct);
    }
  }, [setLocalProduct, updateProductByIndex, index, localProduct]);

  const updateLocalProductFieldValueByPath = useCallback((path: string, val: any) => {
    const _path = path.split(`${PRODUCTS_MODEL_PATH}.*.`)[1];

    const newLocalProduct = setValueByPath(localProduct, val, _path);

    setLocalProduct(newLocalProduct);

    if (newLocalProduct) {
      updateProductByIndex(index, newLocalProduct);
    }
  }, [setLocalProduct, updateProductByIndex, index, localProduct]);

  const getProductFieldValueByPath = useCallback((modelPath: string) => {
    const path = modelPath.split(`${PRODUCTS_MODEL_PATH}.*.`)[1];

    if (!path) return null;

    return getValueByPath(localProduct, path);
  }, [localProduct]);

  const onMarkAsCheckedButtonClick = useCallback(() => {
    onConfirmProduct(index, localProduct.uiId);
  }, [index, localProduct.uiId, onConfirmProduct]);

  const setError = useCallback((key: string, error: string) => {
    setLocalProduct((prev) => {
      if (!prev.errors && !error) return prev;

      const prevErrors = prev.errors || {};

      if (!error) {
        delete prevErrors[key];
      } else {
        prevErrors[key] = error;
      }

      return { ...prev, errors: prevErrors };
    });
    setError_(key, error);
  }, [setLocalProduct, setError_]);

  return (
    <RenderIfVisible
      stayRendered
      placeholderElement={<PlaceHolderElement id={id} dataName={dataName} productName={localProduct.name} />}
    >
      <Accordion.Item
        ref={accordionItemRef}
        id={id}
        data-name={dataName}
        value={value}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        draggable
        className="transition-all duration-300 hover:shadow-md"
      >
        <Control
          errors={localProduct?.errors}
          isProductConfirmed={globalProduct?.confirmed}
          productName={localProduct?.name}
          positionId={localProduct?.positionId}
        />
        <Panel
          product={localProduct}
          fieldSpecs={fieldSpecs}
          schema={schema}
          sortingColumn={sortingColumn}
          filterEnabledProducts={filterEnabledProducts}
          customerId={customerId}
          setError={setError}
          getProductFieldValueByPath={getProductFieldValueByPath}
          updateProductFieldValueByPath={updateLocalProductFieldValueByPath}
          updateProduct={updateLocalProduct}
          onMarkAsCheckedButtonClick={onMarkAsCheckedButtonClick}
        />
      </Accordion.Item>
    </RenderIfVisible>
  );
};

export default ProductField;
