import { useMemo, forwardRef } from 'react';
import {
  ChatBubbleBottomCenterIcon,
  EnvelopeIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';

import { MessageChannel } from '../models/Message';

interface Props {
  source: MessageChannel;
  isRecordingAttached?: boolean;
}

const MessageSourceIcon = forwardRef<HTMLSpanElement, Props>(
  ({ source, isRecordingAttached }, ref) => {
    const IconNode = useMemo(() => {
      if (isRecordingAttached) {
        return <PhoneIcon height={16} width={16} stroke="rgb(97, 108, 118)" />;
      }
      if (source === MessageChannel.IMAP || source === MessageChannel.SMTP) {
        return (
          <EnvelopeIcon height={16} width={16} stroke="rgb(97, 108, 118)" />
        );
      }
      if (source === MessageChannel.VOICE_BRIDGE) {
        return <PhoneIcon height={16} width={16} stroke="rgb(97, 108, 118)" />;
      }
      return (
        <ChatBubbleBottomCenterIcon
          height={16}
          width={16}
          stroke="rgb(97, 108, 118)"
        />
      );
    }, [source, isRecordingAttached]);

    return <span ref={ref}>{IconNode}</span>;
  },
);

export { MessageSourceIcon };
