import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import {
  ReactNode, forwardRef, useMemo, ForwardedRef,
} from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  title?: string;
  icon?: ReactNode;
  disabled?: boolean;

  theme?:
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'indigo'
  | 'red'
  | 'green'
  | 'gray'
  | 'transparent';
  variant?: 'standard' | 'small';
  radius?: 'sm' | 'md' | 'lg' | 'full';

  className?: string;

  onClick: () => void;
  backIcon?: boolean; // TODO: why?
};

const Button = forwardRef(
  (
    {
      title,
      icon,
      theme,
      variant,
      disabled,
      radius,
      onClick,
      backIcon,
      className,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const mainStyle = useMemo(() => {
      let style: string = 'self-end text-[13px]'; // TODO(chihirokuya): create tailwind theme
      switch (theme) {
        case 'secondary':
          style = `${style} bg-white border border-blue-gray-100`;
          break;
        case 'red':
          style = `${style} bg-white border border-blue-gray-100 text-red-500`;
          break;
        case 'green':
          style = `${style} bg-green-500 text-white`;
          break;
        case 'gray':
          style = `${style} bg-gray-500 text-white`;
          break;
        case 'warning':
          style = `${style} bg-warning-button`;
          break;
        case 'indigo':
          style = `${style} bg-indigo-500/90 hover:bg-indigo-500`;
          break;
        case 'transparent':
          style = `${style} bg-transparent`;
          break;
        case 'primary':
        default:
          style = `${style} bg-primary-500 hover:bg-primary-600`;
          break;
      }

      return style;
    }, [theme]);

    const variantStyle = useMemo(() => {
      let style = '';
      switch (variant) {
        case 'small':
          style = 'h-[26px] min-h-[26px] max-h-[26px]';
          break;
        case 'standard':
        default:
          style = 'h-[35px] min-h-[35px] max-h-[35px]';
          break;
      }

      return style;
    }, [variant]);

    const radiusStyle = useMemo(() => {
      let style = '';
      switch (radius) {
        case 'sm':
          style = 'rounded-sm';
          break;
        case 'md':
          style = 'rounded-md';
          break;
        case 'lg':
          style = 'rounded-lg';
          break;
        case 'full':
          style = 'rounded-md';
          break;
        default:
          break;
      }

      return style;
    }, [radius]);

    const titleStyle: string = useMemo(() => {
      const style: string = '';
      switch (theme) {
        case 'primary':
        case 'indigo':
          return `${style} text-white`;
        case 'secondary':
          return `${style} text-blue-gray-200`;
        default:
          return style;
      }
    }, [theme]);

    return (
      <button
        ref={ref}
        type="button"
        disabled={disabled}
        onClick={onClick}
        className={twMerge(
          mainStyle,
          variantStyle,
          disabled
            ? 'opacity-40'
            : 'shadow-sm transition-shadow hover:shadow-md active:shadow-none',
          'flex items-center gap-sm px-lg font-semibold',
          icon || backIcon ? 'justify-between' : 'justify-center',
          radiusStyle,
          className,
        )}
        {...rest}
      >
        {backIcon && !icon ? (
          <ArrowLeftIcon
            height={14}
            width={14}
            style={{ stroke: 'rgb(84, 110, 122)' }}
          />
        ) : null}
        {icon ?? null}
        {title ? <div className={titleStyle}>{title}</div> : null}
      </button>
    );
  },
);

Button.defaultProps = {
  title: null,
  icon: null,
  theme: 'primary',
  disabled: false,
  radius: 'full',
};

export { Button };
