import { useRef } from 'react';
import { EnvelopeOpenIcon } from '@heroicons/react/24/outline';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

import { MessagesFilter as MessagesFilterComponent } from 'features/message/components/messages-filter';
import { Tooltip } from '@mantine/core';
import { MessagesFilter } from 'hooks/fetch/useFetchMessages';
import { Message } from 'models/Message';

interface Props {
  allSelected: boolean;
  toggleAll: () => void;
  reload: () => void;
  markMessagesAsRead: () => void;
  selectedMessages: Message[];
  messagesFilter: MessagesFilter;
  setMessagesFilter: (filter: MessagesFilter) => void;
}

const MessageTableHeader = ({
  allSelected,
  toggleAll,
  reload,
  markMessagesAsRead,
  selectedMessages,
  messagesFilter,
  setMessagesFilter,
}: Props) => {
  const checkbox = useRef<HTMLInputElement>();

  return (
    <div className="sticky top-0 z-10 border-b border-gray-300 bg-white drop-shadow-sm">
      <div className="flex items-center gap-3 py-2">
        <div className="relative px-5">
          <input
            type="checkbox"
            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            ref={checkbox}
            checked={allSelected}
            onChange={toggleAll}
          />
        </div>
        <Tooltip
          label="Refresh"
          transitionProps={{
            transition: 'pop',
            duration: 200,
            enterDelay: 100,
          }}
          bg="white"
          styles={{
            tooltip: {
              color: 'black',
              border: '1.5px solid #E8E8E8',
              boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
            },
          }}
        >
          <button
            type="button"
            onClick={reload}
            className="rounded-full p-2 transition-colors delay-100 duration-200 hover:bg-gray-100"
          >
            <ArrowPathIcon className="h-5 w-5 text-gray-600" />
          </button>
        </Tooltip>
        {selectedMessages.length > 0 && (
          <Tooltip
            label="Mark as read"
            transitionProps={{
              transition: 'pop',
              duration: 200,
              enterDelay: 100,
            }}
            bg="white"
            styles={{
              tooltip: {
                color: 'black',
                border: '1.5px solid #E8E8E8',
                boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
              },
            }}
          >
            <button
              type="button"
              onClick={markMessagesAsRead}
              className="rounded-full p-2 transition-colors delay-100 duration-200 hover:bg-gray-100"
            >
              <EnvelopeOpenIcon className="h-5 w-5 text-gray-600" />
            </button>
          </Tooltip>
        )}
        <MessagesFilterComponent
          setMessagesFilter={setMessagesFilter}
          defaultFilter={messagesFilter}
        />
      </div>
    </div>
  );
};

export default MessageTableHeader;
