export const getTooltipLabel = (errors: Record<string, string> | string[]) => {
  let errorValues: string[] = [];

  if (Array.isArray(errors)) {
    errorValues = errors;
  } else {
    errorValues = Object.values(errors || {});
  }

  if (!errorValues.length) return '';

  return (
    <ul className="list-disc px-3">
      {errorValues
        .reduce((acc, _value) => (_value ? [...acc, _value] : acc), [])
        .map((err, index) => <li key={`${err}-${index.toString()}`}>{err}</li>)}
    </ul>
  );
};
