import {
  ChangeEvent,
  createContext, ReactNode, useCallback, useContext, useEffect, useMemo,
  useState,
} from 'react';

import { setValueByPath } from 'helpers/schema';
import * as PROMPT from 'constants/prompt';
import { Unit } from 'models/Product';
import { QuerySearchType, useFetchProducts } from 'hooks/fetch/useFetchProducts';
import { GroupedFields } from 'types/schema';

import { Annotation } from 'components/chat/Chat/ChatMessage/ImageMessage/ImageOverlay/KonvaStage/type';
import { isPointInSquare } from 'components/chat/Chat/ChatMessage/ImageMessage/ImageOverlay/KonvaStage/utils';
import { getGroupKeyByPath } from 'helpers/prompt';
import { useMessagesContext } from 'contexts/useMessagesContext';
import { Order, ProductWithQuantity } from '../models/Order';
import { useOrderContext } from './useOrderContext';

type ProcessOrderContextType = {
  order: Order;
  setOrder: (updateFn: (currentOrder: Order) => Order) => void;
  onOrderDraftProcessed: (orderId: string) => void;

  typeRef: string;
  setTypeRef: (value: string) => void;
  groupedFields: GroupedFields[];
  setGroupedFields: (value: GroupedFields[]) => void;

  errors: Record<string, Record<string, string>>;
  setError: (groupKey: string, key: string, error: string) => void;
  deleteErrorByPrefix: (prefix: string) => void;

  updateValueByPath: (value: any, path: string, indices?: number[]) => void;
  updateProductByIndex: (index: number, values: any) => void;
  onCommentChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  addNewProduct: (product: ProductWithQuantity) => void;
  addNonProductFields: (fields: Record<string, any>[]) => string[];
  addNewParsedProducts: (fields: Record<string, any>[]) => Promise<ProductWithQuantity[]>;
  removeProductByUiIdorPositionId: (id: string) => void;
  removeAllProducts: () => void;

  selectedDocIndex: number;
  setSelectedDocIndex: (value: number) => void;
  selectedDocPageIndex: number;
  setSelectedDocPageIndex: (value: number) => void;
  selectedDocImgIndex: number;
  setSelectedDocImgIndex: (value: number) => void;

  accordionValue: string[]; // for group fields
  setAccordionValue: (value: string[]) => void;
  setFirstAccordionValue: (groupedFields: GroupedFields[]) => void;
  onAccordionItemClick: (groupKey: string) => void;
  confirmedFields: string[]; // for group fields
  onConfirmField: (key: string) => void;
  onConfirmProduct: (index: number, uiId: string) => void;

  hoverId: string | null;
  triggerHoverEffect: (hoverId: string) => void;
  scrollToKeyword: (scrollToId: string, scrollSection?: string) => void;

  annotationsRecord: Record<string, Annotation[]>;
  setAnnotations: (key: string, annotations: Annotation[]) => void;
  setAnnotationIsLoading: (key: string, annotationKey: string, isLoading: boolean) => void;
  removeAnnotationByPoint: (key: string, x: number, y: number) => void;
  addAnnotationProductUiIds: (key: string, annotationKey: string, productUiIds: string[]) => void;
  addAnnotationPopupContent: (key: string, annotationKey: string, popupContent: string) => void;

  productAccordionValue: string[]; // for product fields
  setProductAccordionValue: (value: string[]) => void;
};

const ProcessOrderContext = createContext<ProcessOrderContextType | undefined>(undefined);

type ProcessOrderProviderProps = {
  children: ReactNode;
  onOrderDraftProcessed: (orderId: string) => void;
};

const ProcessOrderProvider: React.FC<ProcessOrderProviderProps> = ({
  children,
  onOrderDraftProcessed,
}: ProcessOrderProviderProps) => {
  const {
    groupOrders, setGroupOrders, selectedIndex,
  } = useOrderContext();
  const { setMessages } = useMessagesContext();

  const { loadProducts } = useFetchProducts({ preventInitialFetch: true });

  const [selectedDocIndex, setSelectedDocIndex] = useState(0);
  const [selectedDocPageIndex, setSelectedDocPageIndex] = useState(0);
  const [selectedDocImgIndex, setSelectedDocImgIndex] = useState(0);

  const [typeRef, setTypeRef] = useState<string>('');
  const [groupedFields, setGroupedFields] = useState<GroupedFields[]>([]);

  const [annotationsRecord, setAnnotationsRecord] = useState<Record<string, Annotation[]>>({});

  const [confirmedFields, setConfirmedFields] = useState<string[]>([]);
  const [accordionValue, setAccordionValue] = useState<string[]>([]);

  const [productAccordionValue, setProductAccordionValue] = useState<string[]>([]);
  const [errors, setErrors] = useState<Record<string, Record<string, string>>>({});

  const [hoverId, setHoverId] = useState<string | null>(null);

  const order = useMemo(() => {
    const currentOrder = groupOrders?.[selectedIndex];
    if (!currentOrder) return null;

    return {
      ...currentOrder,
      // Hack to force re-render of the product field
      products: (currentOrder.products || []).map((product) => ({ ...product })),
    };
  }, [groupOrders, selectedIndex]);

  const productGroupKey = useMemo(() => getGroupKeyByPath(PROMPT.ORDER_DRAFT_PWQ_NAME_PATH, groupedFields), [groupedFields]);

  const setOrder = useCallback((updateFn: (currentOrder: Order) => Order) => {
    setGroupOrders((prevGos) => prevGos.map((prevOrder, i) => (
      i === selectedIndex ? updateFn(prevOrder) : prevOrder
    )));
  }, [selectedIndex, setGroupOrders]);

  const setError = useCallback((groupKey: string, key: string, error: string) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (!newErrors[groupKey]) {
        newErrors[groupKey] = {};
      }

      newErrors[groupKey][key] = error;

      return newErrors;
    });
  }, [setErrors]);

  const deleteErrorByPrefix = useCallback((prefix: string) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };

      Object.keys(newErrors).forEach((key) => {
        Object.keys(newErrors[key]).forEach((subKey) => {
          if (subKey.startsWith(prefix)) {
            delete newErrors[key][subKey];
          }
        });
      });

      return newErrors;
    });
  }, [setErrors]);

  const onAccordionItemClick = useCallback((groupKey: string) => {
    setAccordionValue((prev) => {
      if (prev.includes(groupKey)) {
        return prev.filter((item) => item !== groupKey);
      }

      return [...prev, groupKey];
    });
  }, [setAccordionValue]);

  const setFirstAccordionValue = useCallback((_groupedFields: GroupedFields[]) => {
    setAccordionValue((prev) => {
      if (prev.length > 0 || _groupedFields.length === 0) return prev;

      const firstKey = _groupedFields[0]?.title;

      if (!firstKey) return [];

      return [firstKey];
    });
  }, []);

  const onConfirmField = useCallback((key: string) => {
    setConfirmedFields((prevCFs) => {
      if (prevCFs.includes(key)) {
        return prevCFs.filter((item) => item !== key);
      }

      setAccordionValue((prevAV) => {
        let newAV = prevAV.filter((item) => item !== key);

        const keyIdx = groupedFields.findIndex((group) => group.title === key);
        if (keyIdx + 1 < groupedFields.length
          && !prevCFs.includes(groupedFields[keyIdx + 1].title)) {
          newAV = [...newAV, groupedFields[keyIdx + 1].title];
        }

        return newAV;
      });

      return [...prevCFs, key];
    });
  }, [groupedFields]);

  const onConfirmProduct = useCallback((index: number, uiId: string) => {
    setGroupOrders((prevGos) => {
      const _groupOrders = [...prevGos];

      const confirmed = _groupOrders[selectedIndex].products[index].confirmed;

      if (!confirmed) {
        setProductAccordionValue((prev) => (!prev.includes(
          uiId) ? [...prev, uiId] : prev.filter((id) => id !== uiId)));
      }

      _groupOrders[selectedIndex].products[index].confirmed = !confirmed;
      return _groupOrders.map((prevGroupOrder, i) => (i === selectedIndex
        ? {
          ...prevGroupOrder,
          products: (prevGroupOrder.products || []).map((prevProduct: ProductWithQuantity) => ({ ...prevProduct })),
        } : prevGroupOrder));
    });
  }, [selectedIndex, setGroupOrders]);

  const updateValueByPath = useCallback((value: any, path: string, indices: number[] = []) => {
    setGroupOrders((prevGos) => prevGos.map((prevOrder, i) => {
      if (i === selectedIndex) {
        const newOrder = setValueByPath(prevOrder, value, path, indices);
        return {
          ...newOrder,
          // dirty way to force re-render of the product field
          products: (newOrder.products || []).map((product: ProductWithQuantity) => ({ ...product })),
          didChangeMade: true,
        };
      }
      return prevOrder;
    }));
  }, [selectedIndex, setGroupOrders]);

  const updateProductByIndex = useCallback((index: number, values: any) => {
    setGroupOrders((prevGos) => prevGos.map((prevOrder, i) => (i === selectedIndex
      ? {
        ...prevOrder,
        products: (prevOrder.products || []).map((_product, j) => (j === index
          ? {
            ..._product,
            ...values,
          }
          : _product),
        ),
        didChangeMade: true,
      }
      : prevOrder),
    ),
    );
  }, [selectedIndex, setGroupOrders]);

  const onCommentChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setGroupOrders((prevGos) => prevGos.map((prevOrder, i) => (i === selectedIndex
      ? {
        ...prevOrder,
        draft: { ...prevOrder.draft, comment: event.target.value },
        didChangeMade: true,
      }
      : prevOrder),
    ),
    );
  }, [selectedIndex, setGroupOrders]);

  const addNewProduct = useCallback((product: ProductWithQuantity) => {
    console.log(product);
    setGroupOrders((prevGos) => prevGos.map((prevOrder, i) => (i === selectedIndex
      ? { ...prevOrder, products: [...(prevOrder.products || []), product], didChangeMade: true }
      : prevOrder),
    ),
    );

    setAccordionValue((prev) => {
      if (prev.includes(productGroupKey)) return prev;

      return [...prev, productGroupKey];
    });

    setProductAccordionValue((prev) => [...prev, product.uiId]);

    setTimeout(() => {
      document.getElementById(product.uiId)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 200);
  }, [selectedIndex, setGroupOrders, productGroupKey]);

  const addNonProductFields = useCallback((fields: Record<string, any>[]): string[] => {
    const nonProductValues: string[] = [];

    type KeyAndFields = {
      groupKey: string;
      fields: Record<string, any>[];
    };

    // Group fields by group key
    const keyAndFields: Record<string, KeyAndFields> = fields.reduce(
      (acc: Record<string, KeyAndFields>, field: Record<string, any>) => {
        const paths = Object.keys(field) || [];
        if (paths.length === 0) return acc;

        let groupKey = '';

        paths.every((path) => {
          const _groupKey = getGroupKeyByPath(path, groupedFields);
          if (_groupKey) {
            groupKey = _groupKey;
            return false;
          }
          return true;
        });

        if (!groupKey) return acc;

        if ((Object.keys(acc) || []).includes(groupKey)) {
          acc[groupKey].fields.push(field);
        } else {
          acc[groupKey] = { groupKey, fields: [field] };
        }

        return acc;
      }, {},
    );

    // Process all fields except for product fields
    Object.keys(keyAndFields).forEach((groupKey) => {
      if (groupKey === productGroupKey) return;

      const { fields: nonProductFields } = keyAndFields[groupKey];

      setAccordionValue((prev) => {
        if (prev.includes(groupKey)) return prev;

        return [...prev, groupKey];
      });

      nonProductFields.forEach((field) => {
        Object.keys(field).forEach((key) => {
          const value = field[key];
          updateValueByPath(value, key.replace('order_drafts.*.', ''));
          nonProductValues.push(value);
        });
      });
    });

    return nonProductValues;
  }, [groupedFields, updateValueByPath, productGroupKey]);

  const addNewParsedProducts = useCallback(async (fields: Record<string, any>[]) => {
    const newProducts: ProductWithQuantity[] = await Promise.all(fields.map(async (field: Record<string, any>) => {
      const newProduct = new ProductWithQuantity({
        name: field[PROMPT.ORDER_DRAFT_PWQ_NAME_PATH],
        quantity: field[PROMPT.ORDER_DRAFT_PWQ_QUANTITY_PATH],
        unit: new Unit({
          symbol: field[PROMPT.ORDER_DRAFT_PWQ_UNIT_PATH],
        }),
        autoMatchedUnit: new Unit({
          symbol: field[PROMPT.ORDER_DRAFT_PWQ_UNIT_PATH],
        }),
      });

      if (!newProduct.quantity) {
        return null;
      }

      try {
        const products = await loadProducts({
          searchQuery: newProduct.name,
          customerId: order?.createdBy,
          querySearchType: QuerySearchType.Embedding,
          filterEnabledProducts: true,
          reset: true,
        });

        const product = products?.[0];
        newProduct.product = product;
        newProduct.id = product?.id;
      } catch {
        /* do nothing */
      }

      addNewProduct(newProduct);
      return newProduct;
    }));

    return newProducts.filter((product) => product !== null);
  }, [addNewProduct, loadProducts, order?.createdBy]);

  const removeProductByUiIdorPositionId = useCallback((id: string) => {
    setProductAccordionValue((prev) => prev.filter((id_) => id_ !== id));

    deleteErrorByPrefix(`${id}-`);

    setGroupOrders((groupOrders_) => {
      const newGroupOrders = [...groupOrders_];
      newGroupOrders[selectedIndex].products = newGroupOrders[selectedIndex].products.filter(
        (product) => product.uiId !== id && product.positionId !== id);
      newGroupOrders[selectedIndex].didChangeMade = true;
      return newGroupOrders;
    });

    setMessages((prevMessages) => prevMessages.map((message) => {
      const newMessage = { ...message };
      if (newMessage?.context?.workflowOrder?.ocrKeywords) {
        newMessage.context.workflowOrder.ocrKeywords = (
          newMessage.context.workflowOrder.ocrKeywords.filter((keyword) => keyword.fieldId !== id)
        );
      }
      return newMessage;
    }));
  }, [selectedIndex, setGroupOrders, deleteErrorByPrefix, setMessages]);

  const removeAllProducts = useCallback(() => {
    setProductAccordionValue([]);
    setAnnotationsRecord({});
    order?.products?.forEach((product) => {
      removeProductByUiIdorPositionId(product.uiId);
    });
  }, [setProductAccordionValue, order?.products, removeProductByUiIdorPositionId]);

  const removeAnnotationByPoint = useCallback((key: string, x: number, y: number) => {
    setAnnotationsRecord((prevRecord) => {
      const newRecord = { ...prevRecord };

      newRecord[key] = newRecord[key].filter((annotation) => {
        const toErase = isPointInSquare(x, y, annotation);

        if (toErase) {
          (annotation.productUiIds || []).forEach((productUiId) => {
            removeProductByUiIdorPositionId(productUiId);
          });
        }

        return !toErase;
      });

      return newRecord;
    });
  }, [removeProductByUiIdorPositionId]);

  const setAnnotations = useCallback((key: string, annotations: Annotation[]) => {
    setAnnotationsRecord((prev) => ({ ...prev, [key]: annotations }));
  }, [setAnnotationsRecord]);

  const setAnnotationIsLoading = useCallback((key: string, annotationKey: string, isLoading: boolean) => {
    setAnnotationsRecord((prevRecord) => {
      const newRecord = { ...prevRecord };
      newRecord[key] = newRecord[key].map((annotation) => (annotation.key === annotationKey
        ? { ...annotation, isLoading } : annotation));
      return newRecord;
    });
  }, [setAnnotationsRecord]);

  const addAnnotationProductUiIds = useCallback((key: string, annotationKey: string, productUiIds: string[]) => {
    setAnnotationsRecord((prevRecord) => {
      const newRecord = { ...prevRecord };
      newRecord[key] = newRecord[key].map((annotation) => (annotation.key === annotationKey
        ? { ...annotation, productUiIds } : annotation));
      return newRecord;
    });
  }, []);

  const addAnnotationPopupContent = useCallback((key: string, annotationKey: string, popupContent: string) => {
    setAnnotationsRecord((prevRecord) => {
      const newRecord = { ...prevRecord };
      newRecord[key] = newRecord[key].map((annotation) => (annotation.key === annotationKey
        ? { ...annotation, popupContent } : annotation));
      return newRecord;
    });
  }, [setAnnotationsRecord]);

  useEffect(() => {
    setAccordionValue([]);
    setConfirmedFields([]);
  }, [order?.id]);

  const scrollToKeyword = useCallback((scrollToId: string, scrollSection?: string) => {
    // TODO: right now we always assume it needs to scroll to the product field
    setAccordionValue((prev) => (prev.includes(scrollSection || productGroupKey)
      ? prev
      : [...prev, scrollSection || productGroupKey]));

    setTimeout(() => {
      let element = document.getElementById(scrollToId);
      if (!element) {
        element = document.querySelector(`[data-name="${scrollToId}"]`);
      }
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 200);
  }, [productGroupKey]);

  const triggerHoverEffect = useCallback((id: string) => {
    setHoverId(id);
  }, [setHoverId]);

  const contextValue = useMemo(
    () => ({
      order,
      errors,
      setError,
      deleteErrorByPrefix,
      setOrder,
      onOrderDraftProcessed,
      updateValueByPath,
      updateProductByIndex,
      onCommentChange,
      addNewProduct,
      addNonProductFields,
      addNewParsedProducts,
      removeProductByUiIdorPositionId,
      removeAllProducts,
      typeRef,
      setTypeRef,
      groupedFields,
      setGroupedFields,

      selectedDocIndex,
      setSelectedDocIndex,
      selectedDocPageIndex,
      setSelectedDocPageIndex,
      selectedDocImgIndex,
      setSelectedDocImgIndex,

      onConfirmField,
      onConfirmProduct,
      setFirstAccordionValue,
      accordionValue,
      setAccordionValue,
      productAccordionValue,
      setProductAccordionValue,
      confirmedFields,
      onAccordionItemClick,
      annotationsRecord,
      setAnnotations,
      setAnnotationIsLoading,
      removeAnnotationByPoint,
      addAnnotationProductUiIds,
      addAnnotationPopupContent,
      scrollToKeyword,
      triggerHoverEffect,
      hoverId,
    }),
    [onOrderDraftProcessed, updateValueByPath, updateProductByIndex,
      onCommentChange, addNewProduct, addNonProductFields, addNewParsedProducts, order, setOrder,
      productAccordionValue, setProductAccordionValue, errors, setError, deleteErrorByPrefix,
      removeProductByUiIdorPositionId, removeAllProducts,
      selectedDocIndex, setSelectedDocIndex, selectedDocPageIndex, setSelectedDocPageIndex,
      selectedDocImgIndex, setSelectedDocImgIndex,
      accordionValue, setAccordionValue, confirmedFields, typeRef, groupedFields, onConfirmField,
      setFirstAccordionValue, onAccordionItemClick, annotationsRecord, setAnnotations, setAnnotationIsLoading,
      removeAnnotationByPoint, addAnnotationProductUiIds, addAnnotationPopupContent, scrollToKeyword,
      triggerHoverEffect, hoverId, onConfirmProduct,
    ],
  );

  return (
    <ProcessOrderContext.Provider value={contextValue}>
      {children}
    </ProcessOrderContext.Provider>
  );
};

const useProcessOrderContext = () => {
  const context = useContext(ProcessOrderContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by ProcessOrderContext provider.');
  }

  return context;
};

export { ProcessOrderContext, useProcessOrderContext, ProcessOrderProvider };
