import {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { Button } from 'components/ui/Button';
import { CustomersTable, CustomerSearchBar } from 'components/customer/customer-list';
import { Page } from 'components/layout/Page/Page';
import { CustomerDetailSidebarWrapper } from 'components/wrapper/CustomerDetailSidebarWrapper';
import { Tooltip } from '@mantine/core';
import { genericErrorFeedback } from '../../../helpers/errors';
import { httpGet } from '../../../helpers/xhr';
import { Business, Phone } from '../../../models/Business';

interface Props {
  navigateToCustomersImport: () => void;
}

interface GetCustomersRequestParam {
  search_query?: string;
}

const CustomerListPage = ({ navigateToCustomersImport }: Props) => {
  const [customers, setCustomers] = useState<Business[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState<number>(null);
  const [requestParam, setRequestParam] = useState<GetCustomersRequestParam>(
    {},
  );
  const customersPaginationCursor = useRef();

  const customerDetailSidebarWrapperRef = useRef(null);

  const onImportClick = useCallback(() => {
    navigateToCustomersImport();
  }, [navigateToCustomersImport]);

  const onSearchClicked = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const query = formData.get('search_query');

    const params: GetCustomersRequestParam = {};
    if (query) {
      params.search_query = query.toString();
    }

    setRequestParam(params);
    setIsLoading(true);

    httpGet('/businesses/me/customers', { params })
      .then((response) => {
        const e = response.data.result || [];
        if (response.data.cursor === '') {
          // TODO: refine
          setIsLoading(false);
        }
        setCustomers(
          e.map((b: Business) => ({
            ...b,
            phones: b.phones?.map(
              (p, i) => ({ ...p, uiId: i.toString() }) as Phone,
            ),
          })),
        );
        customersPaginationCursor.current = response.data.cursor;
      })
      .catch(genericErrorFeedback('Error loading products'))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const openDialog = useCallback((idx: number) => {
    setSelectedIdx(idx);
    if (customerDetailSidebarWrapperRef.current) {
      customerDetailSidebarWrapperRef.current.onManageCustomerButtonClick();
    }
  }, []);

  const onCustomerEditComplete = useCallback(
    (_customer: Business) => {
      setCustomers((c) => c.map((customer, idx) => (idx === selectedIdx ? _customer : customer)),
      );
    },
    [selectedIdx],
  );

  const onScrolledEnd = useCallback(() => {
    if (!isLoading) {
      setIsLoading(true);
      httpGet('/businesses/me/customers', {
        params: { ...requestParam, cursor: customersPaginationCursor.current },
      })
        .then((response) => {
          const e = response.data.result || [];
          if (response.data.cursor === '') {
            setIsLoading(false);
          }
          setCustomers((c) => [...c, ...e].map((b: Business) => ({
            ...b,
            phones: b.phones?.map(
              (p, i) => ({ ...p, uiId: i.toString() }) as Phone,
            ),
          })),
          );
          customersPaginationCursor.current = response.data.cursor;
        })
        .catch(genericErrorFeedback('Error loading customers'))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isLoading, requestParam]);

  useEffect(() => {
    setIsLoading(true);
    httpGet('/businesses/me/customers', {
      params: { cursor: customersPaginationCursor.current },
    })
      .then((response) => {
        const e = response.data.result || [];
        if (response.data.cursor === '') {
          setIsLoading(false);
        }
        setCustomers((c) => [...c, ...e].map((b: Business) => ({
          ...b,
          phones: b.phones?.map(
            (p, i) => ({ ...p, uiId: i.toString() }) as Phone,
          ),
        })),
        );
        customersPaginationCursor.current = response.data.cursor;
      })
      .catch(genericErrorFeedback('Error loading customers'))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Page isLoading={isLoading}>
      <CustomerDetailSidebarWrapper
        ref={customerDetailSidebarWrapperRef}
        customer={customers[selectedIdx]}
        onComplete={onCustomerEditComplete}
      >
        <div className="m-lg flex flex-1 flex-col gap-lg rounded-2xl border border-solid bg-white p-lg">
          <div className="flex justify-between gap-lg">
            <CustomerSearchBar onSubmit={onSearchClicked} />
            <Tooltip position="bottom" label="This feature is currently not available">
              <Button
                title="Import"
                onClick={onImportClick}
                disabled
              />
            </Tooltip>
          </div>

          <CustomersTable
            customers={customers}
            openDialog={openDialog}
            isLoading={isLoading}
            onScrolledEnd={onScrolledEnd}
          />
        </div>
      </CustomerDetailSidebarWrapper>
    </Page>
  );
};

export default CustomerListPage;
