import { Tooltip } from '@mantine/core';
import { Button } from 'components/ui/Button';
import { getTooltipLabel } from '../utils';

const Buttons = ({
  onRemoveProductButtonClick,
  onMarkAsCheckedButtonClick,
  errors,
  isProductConfirmed,
}: {
  onRemoveProductButtonClick: () => void;
  onMarkAsCheckedButtonClick: () => void;
  errors: Record<string, string>;
  isProductConfirmed: boolean;
}) => {
  const tooltipLabel = getTooltipLabel(errors);

  return (
    <div className="pt-4 flex justify-end">
      <div className="flex space-x-2">
        <Button
          title="Remove"
          theme="red"
          onClick={onRemoveProductButtonClick}
        />
        <Tooltip
          label={tooltipLabel}
          disabled={!tooltipLabel}
        >
          <Button
            title={isProductConfirmed ? 'Mark as unchecked' : 'Mark as checked'}
            onClick={onMarkAsCheckedButtonClick}
            disabled={!!tooltipLabel}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Buttons;
