import { useCallback, useMemo, useRef } from 'react';

import LoadingOverlay from 'components/ui/LoadingOverlay';

import { useDeclineOrderDraft } from 'features/order/api/useDeclineOrderDraft';
import { useUpdateOrderDrafts } from 'features/order/api/useUpdateOrderDrafts';
import { useConfirmOrderDraft } from 'features/order/api/useConfirmOrderDraft';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { useOrderContext } from 'features/order/contexts/useOrderContext';

import { ConfirmOrderModalWrapper } from 'components/wrapper/ConfirmOrderModalWrapper';
import { useConfirmOrderDraftsByGroupId } from 'features/order/api/useConfirmOrderDraftsByGroupId';
import { isZeroId } from 'helpers/objectId';

import { ButtonSection } from './ButtonSection';
import { Tabs } from './Tabs';
import { OrderDraftPromptRender } from '../../prompt-render';

interface Props {
  orderDraftsProcessingMode: boolean;
  onCancel: () => void;

  isNewOrderDraft?: boolean;
}

const Body = ({
  orderDraftsProcessingMode,
  onCancel,

  isNewOrderDraft,
}: Props) => {
  const { onOrderDraftProcessed } = useProcessOrderContext();

  const { isLoading: isDeclineOrderDraftLoading, declineOrderDraft } = useDeclineOrderDraft();
  const { isLoading: isSaveAndCreateOrderDraftsLoading, updateOrderDrafts } = useUpdateOrderDrafts();
  const { isLoading: isConfirmOrderDraftLoading, confirmOrderDraft } = useConfirmOrderDraft();
  const {
    isLoading: isConfirmOrderDraftsByGroupIdLoading,
    confirmOrderDraftsByGroupId,
  } = useConfirmOrderDraftsByGroupId();
  const { groupOrders, selectedIndex: tabIndex } = useOrderContext();

  const confirmOrderModalWrapperRef = useRef(null);

  const order = useMemo(() => groupOrders[tabIndex], [tabIndex, groupOrders]);

  const loadingText = useMemo(() => {
    if (!order) return 'Loading order';
    if (isDeclineOrderDraftLoading) return 'Declining order';
    if (isSaveAndCreateOrderDraftsLoading) return 'Saving order';
    if (isConfirmOrderDraftLoading || isConfirmOrderDraftsByGroupIdLoading) return 'Confirming order';
    return '';
  }, [order, isDeclineOrderDraftLoading, isSaveAndCreateOrderDraftsLoading,
    isConfirmOrderDraftLoading, isConfirmOrderDraftsByGroupIdLoading]);

  const onConfirm = useCallback(() => {
    if (order.groupId && !isZeroId(order.groupId)) {
      confirmOrderDraftsByGroupId(order.groupId).then(() => {
        if (orderDraftsProcessingMode) onOrderDraftProcessed(groupOrders.find((o) => !o.isGroupSibling)?.id);
        else onCancel?.();
      });
    } else {
      confirmOrderDraft(order.id).then(() => {
        if (orderDraftsProcessingMode) onOrderDraftProcessed(order.id);
        else onCancel?.();
      });
    }
  }, [
    confirmOrderDraft,
    confirmOrderDraftsByGroupId,
    groupOrders,
    onCancel,
    onOrderDraftProcessed,
    order.groupId,
    order.id,
    orderDraftsProcessingMode,
  ]);

  const onConfirmButtonClick = useCallback(() => {
    if (
      !order
      || isConfirmOrderDraftLoading
      || isConfirmOrderDraftsByGroupIdLoading
    ) return;

    // If it's a group order, ask for the confirmation. Otherwise, confirm the order
    if (order.groupId && !isZeroId(order.groupId)) {
      confirmOrderModalWrapperRef.current?.openModal();
    } else {
      onConfirm();
    }
  }, [
    onConfirm,
    isConfirmOrderDraftLoading,
    isConfirmOrderDraftsByGroupIdLoading,
    order,
  ]);

  return (
    <ConfirmOrderModalWrapper
      ref={confirmOrderModalWrapperRef}
      onConfirm={onConfirm}
    >
      <div className="flex flex-1 flex-col overflow-hidden">
        <Tabs />

        <div className="relative mt-2 flex flex-1 flex-col overflow-hidden">
          {
            loadingText ? (
              <LoadingOverlay
                visible
                displayText={loadingText}
              />
            ) : (
              <div className="hidden-scrollbar flex-1 overflow-y-scroll">
                <div className="flex flex-col gap-lg">
                  <OrderDraftPromptRender />
                </div>
              </div>
            )
          }
        </div>

        <div className="flex h-fit justify-between border-t border-t-blue-gray-50 bg-white pt-lg">
          <ButtonSection
            orderDraftsProcessingMode={orderDraftsProcessingMode}
            onCancel={onCancel}
            isNewOrderDraft={isNewOrderDraft}
            isInnerLoading={loadingText !== ''}
            onConfirmButtonClick={onConfirmButtonClick}
            updateOrderDrafts={updateOrderDrafts}
            declineOrderDraft={declineOrderDraft}
          />
        </div>
      </div>
    </ConfirmOrderModalWrapper>
  );
};

export default Body;
