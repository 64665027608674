import {
  memo, useCallback, useEffect, useMemo,
} from 'react';

import { useFetchCustomers } from 'hooks/fetch/useFetchCustomers';
import { Select, SelectOption } from 'components/ui/Select';
import { Business } from 'models/Business';
import { getAdditionalInfo } from 'helpers/customer';
import LoadingOverlay from 'components/ui/LoadingOverlay';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';

interface Props {
  setError?: (error: string) => void;
}

const getCustomerVisibleId = (customer: Business): string => {
  if (!customer) return '';

  if (customer.shortName && customer.shortName !== '') return customer.shortName;

  if (customer.externalId && customer.externalId !== '') return customer.externalId;

  return '';
};

const CustomerSelect = memo(({
  setError,
}: Props) => {
  const { order, setOrder } = useProcessOrderContext();

  const {
    customers,
    isLoading: isCustomerLoading,
    loadCustomers,
    setSearchQuery,
  } = useFetchCustomers({ preventInitialFetch: true });

  const customerError = useMemo(() => {
    if (!order?.customer?.customer?.isConfirmed) return 'Manage the unknown customer';

    return '';
  }, [order?.customer?.customer?.isConfirmed]);

  // Customer selection related functions
  const customerOptions = useMemo<SelectOption[]>(
    () => customers.map((c) => {
      const additionalInfo = getAdditionalInfo(c);

      return ({
        visibleId: getCustomerVisibleId(c),
        label: c.name,
        flag: c?.disabled ? 'Disabled' : null,
        value: c.id,
        additionalInfo: (
          <div>
            {additionalInfo.map((info) => (
              <p key={info}>{info}</p>
            ))}
          </div>
        ),
      });
    }),
    [customers],
  );

  const selectedCustomerOption = useMemo<SelectOption>(
    () => (order?.customer
      ? {
        visibleId: getCustomerVisibleId(order?.customer),
        flag: order.customer.disabled ? 'Disabled' : null,
        label: order.customer.name,
        value: order.customer.id,
        additionalInfo: (
          <div>
            {getAdditionalInfo(order.customer).map((info) => (
              <p key={info}>{info}</p>
            ))}
          </div>
        ),
      }
      : null),
    [order?.customer],
  );

  const onCustomerSelectionChange = useCallback(
    (option: SelectOption) => {
      setOrder((order_) => ({
        ...order_,
        customer: customers.find((c) => c.id === option.value),
      }));
    },
    [customers, setOrder],
  );

  const onCustomerSearchPerformed = useCallback(
    (search: string) => {
      setSearchQuery(search);
    },
    [setSearchQuery],
  );

  const onCustomerSelectOptionsScrolledEnd = useCallback(() => {
    loadCustomers({});
  }, [loadCustomers]);

  const onDropDownOpen = useCallback(() => {
    if (!customers.length) {
      loadCustomers({ reset: true });
    }
  }, [loadCustomers, customers]);

  useEffect(() => {
    setError?.(customerError);
  }, [customerError, setError]);

  if (!customers) return <LoadingOverlay visible />;

  return (
    <Select
      label="Customer"
      searchPlaceholder="Type here to search customers..."
      dropdownPlaceHolder="Type a customer name or keyword to search"
      emptyPlaceholder="No customers found"
      error={setError ? customerError : null}
      selectedOption={selectedCustomerOption}
      options={customerOptions}
      isLoading={isCustomerLoading}
      isExternalSearchEnabled
      onSelectionChange={onCustomerSelectionChange}
      onSearchPerformed={onCustomerSearchPerformed}
      onScrolledEnd={onCustomerSelectOptionsScrolledEnd}
      onDropdownOpen={onDropDownOpen}
    />
  );
});

export default CustomerSelect;
