import {
  useCallback, useState,
} from 'react';

import { Page } from 'components/layout/Page/Page';
import { SettingSection } from 'components/settings/Feed/types';

import Feed from 'components/settings/Feed';

import BasicSettings from 'components/settings/SettingCard/BasicSettings';
import ERPOverview from 'components/settings/SettingCard/ERP/Overview';
import ERPModels from 'components/settings/SettingCard/ERP/Models';
import { ScrollAreaWrapper } from 'components/wrapper/ScrollAreaWrapper';
import { httpPut } from 'helpers/xhr';
import { globalAlertData } from 'state/globalAlertData';
import { genericErrorFeedback } from 'helpers/errors';
import { valueToDecimalComma } from 'helpers/basicSettings';
import { useFetchBusinessSettings } from 'hooks/fetch/useFetchBusinessSettings';

// const testSetting: BusinessSettings = {
//   defaultCurrency: 'CHF',
//   businessTimes: [{ from: null, to: null }],
//   erpConnectionSettings: {
//     type: ErpConnectionType.SFTP,
//     sftp: {
//       host: '46.14.53.250:22',
//       user: 'hoshii',
//       auth: {
//         type: 'password',
//         password: '',
//       },
//     },
//     syncSettings: {
//       type: 'lazy',
//       syncedEntities: {
//         customer: {
//           enabled: true,
//           direction: 'from_erp',
//           interval: '24m0s',
//           softDeleteExcludedDocuments: false,
//           matcherId: '663b9f3e135be35b5062b9a6',
//           encoding: '',
//           inputSearchDirectory: 'Share/Stammdaten_Hoshii',
//           inputFilenameRegex: '^customers.*\\.csv$',
//           outputDirectory: '',
//           outputFormat: '',
//           outputFilenameFormat: '',
//           outputFilenameFormatArgs: null,
//           outputMaxRecordsPerFile: null,
//         },
//         order: {
//           enabled: true,
//           direction: 'to_erp',
//           interval: '1h0m0s',
//           softDeleteExcludedDocuments: false,
//           matcherId: '663b9f3e135be35b5062b9a7',
//           encoding: '',
//           inputSearchDirectory: '',
//           inputFilenameRegex: '',
//           outputDirectory: 'Share/Bestellungen_Hoshii',
//           outputFormat: 'tsv',
//           outputFilenameFormat: 'order%07d.csv',
//           outputFilenameFormatArgs: ['external_id'],
//           outputMaxRecordsPerFile: 1,
//         },
//         product: {
//           enabled: true,
//           direction: 'from_erp',
//           interval: '24h0m0s',
//           softDeleteExcludedDocuments: true,
//           matcherId: '65ee02a989b6f6c7508df9e9',
//           encoding: '',
//           inputSearchDirectory: 'Share/Stammdaten_Hoshii',
//           inputFilenameRegex: '^products.*\\.csv$',
//           outputDirectory: '',
//           outputFormat: '',
//           outputFilenameFormat: '',
//           outputFilenameFormatArgs: null,
//           outputMaxRecordsPerFile: null,
//         },
//       },
//     },
//   },
// };

// const testSetting2: BusinessSettings = {
//   ...testSetting,
//   defaultCurrency: 'CHF',
//   businessTimes: [{ from: null, to: null }],
//   erpConnectionSettings: {
//     type: ErpConnectionType.Abacus,
//     abacus: {
//       baseURL: 'https://abatest.adank.ch/api/entity/v1/mandants/2000',
//       clientID: '33bcb868-2e3e-f796-6789-87798106d691',
//       grantType: 'client_credentials',
//       oAuthBaseURL: 'https://abatest.adank.ch',
//     },
//     dynamicsBc: null,
//     sftp: null,
//     syncSettings: {
//       type: 'lazy',
//       lazy: {
//         interval: '24h0m0s',
//       },
//       syncedEntities: {
//         customer: {
//           enabled: true,
//           direction: 'from_erp',
//           interval: '10m0s',
//           softDeleteExcludedDocuments: false,
//           matcherId: '667152305af1793de9ff0fa4',
//           encoding: '',
//           inputSearchDirectory: '',
//           inputFilenameRegex: '',
//           outputDirectory: '',
//           outputFormat: '',
//           outputFilenameFormat: '',
//           outputFilenameFormatArgs: null,
//           outputMaxRecordsPerFile: null,
//         },
//         order: {
//           enabled: true,
//           direction: 'to_erp',
//           interval: '240h0m0s',
//           softDeleteExcludedDocuments: false,
//           matcherId: '663b9f3e135be35b5062b9af',
//           encoding: '',
//           inputSearchDirectory: '',
//           inputFilenameRegex: '',
//           outputDirectory: '',
//           outputFormat: '',
//           outputFilenameFormat: '',
//           outputFilenameFormatArgs: null,
//           outputMaxRecordsPerFile: null,
//         },
//         product: {
//           enabled: true,
//           direction: 'from_erp',
//           interval: '240h0m0s',
//           softDeleteExcludedDocuments: false,
//           matcherId: '667150cf5af1793de9fee93c',
//           encoding: '',
//           inputSearchDirectory: '',
//           inputFilenameRegex: '',
//           outputDirectory: '',
//           outputFormat: '',
//           outputFilenameFormat: '',
//           outputFilenameFormatArgs: null,
//           outputMaxRecordsPerFile: null,
//         },
//       },
//     },
//   },
// };

const settingSections: SettingSection[] = [
  {
    title: 'Basic Settings',
    sectionId: 'basic_settings',
  },
  {
    title: 'ERP Integration',
    sectionId: 'erp_integration',
    children: [
      {
        title: 'Overview',
        sectionId: 'erp_integration_overview',
      },
      {
        title: 'Models',
        sectionId: 'erp_integration_models',
      },
    ],
  },
];

const sectionIds = [
  ...settingSections
    .filter((s) => !s.children || s.children.length === 0)
    .map((s) => s.sectionId),
  ...settingSections
    .filter((s) => s.children && s.children.length > 0)
    .map((s) => s.children?.map((c) => c.sectionId))
    .flat(),
];

const SettingsPage = () => {
  const {
    businessSettings,
    setBusinessSettings,
    isLoading: isLoadingSettings,
  } = useFetchBusinessSettings({});

  const [currentSectionId, setCurrentSectionId] = useState<string>(
    settingSections[0].sectionId,
  );
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const isLoading = isLoadingSettings || isLoadingSave;

  const onDefaultCurrencyChange = useCallback((value: string) => {
    setBusinessSettings((bs) => ({
      ...bs,
      defaultCurrency: value,
    }));
  }, [setBusinessSettings]);

  const onDecimalCommaChange = useCallback((value: 'Dot (0.1)' | 'Comma (0,1)') => {
    setBusinessSettings((bs) => ({
      ...bs,
      decimalComma: valueToDecimalComma(value),
    }));
  }, [setBusinessSettings]);

  const onDefaultTranscriptionLanguageChange = useCallback((value: string) => {
    setBusinessSettings((bs) => ({
      ...bs,
      defaultTranscriptionLanguageCode: value,
    }));
  }, [setBusinessSettings]);

  const onAddBusinessTimesIntervalButtonClick = useCallback(() => {
    setBusinessSettings((bs) => ({
      ...bs,
      businessTimes: [
        ...(bs.businessTimes || []),
        {
          from: null,
          to: null,
        },
      ],
    }));
  }, [setBusinessSettings]);

  const onBusinessTimesTimeChange = useCallback(
    (index: number, from: string, to: string) => {
      setBusinessSettings((bs) => ({
        ...bs,
        businessTimes: (bs.businessTimes || []).map((bt, i) => {
          if (i === index) {
            return {
              ...bt,
              from,
              to,
            };
          }
          return bt;
        }),
      }));
    },
    [setBusinessSettings],
  );

  const onRemoveBusinessTimesIntervalButtonClick = useCallback(
    (index: number) => {
      setBusinessSettings((bs) => ({
        ...bs,
        businessTimes: bs.businessTimes?.filter((_, i) => i !== index),
      }));
    },
    [setBusinessSettings],
  );

  const onSaveBusinessDetailsButtonClick = useCallback(() => {
    setIsLoadingSave(true);
    httpPut('/businesses/me/settings', {
      ...businessSettings,
      businessTimes: businessSettings?.businessTimes?.filter(
        (bt) => bt.from && bt.to,
      ),
    })
      .then(() => {
        globalAlertData.create('Business settings saved correctly');
      })
      .catch(genericErrorFeedback('Error saving business settings'))
      .finally(() => {
        setIsLoadingSave(false);
      });
  }, [businessSettings]);

  const handleScroll = () => {
    for (let i = 0; i < sectionIds.length; i += 1) {
      const section = document.getElementById(sectionIds[i]);
      if (!section) return;
      if (section.getBoundingClientRect().top >= 0) {
        setCurrentSectionId(sectionIds[i]);
        break;
      }
    }
  };

  return (
    <Page isLoading={isLoading} contentWithPadding>
      <div className="flex flex-1 justify-center overflow-hidden p-lg">
        <div className="flex flex-col space-y-4 overflow-hidden">
          <div className="flex justify-between">
            <div className="flex flex-col justify-center gap-2">
              <h1 className="text-xl font-semibold leading-none text-gray-900">
                Business Settings
              </h1>
              <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
                Manage your business preferences
              </div>
            </div>
          </div>
          <div className="flex min-w-[60vw] flex-1 space-x-4 overflow-hidden">
            <Feed
              sections={settingSections}
              currentSectionId={currentSectionId}
            />
            <ScrollAreaWrapper
              className="flex flex-1 flex-col"
              onScroll={handleScroll}
              offsetScrollbar
            >
              <div className="flex-1 space-y-4 pb-[200px]">
                <BasicSettings
                  businessSettings={businessSettings}
                  onBusinessTimesTimeChange={onBusinessTimesTimeChange}
                  onRemoveBusinessTimesIntervalButtonClick={
                    onRemoveBusinessTimesIntervalButtonClick
                  }
                  onAddBusinessTimesIntervalButtonClick={
                    onAddBusinessTimesIntervalButtonClick
                  }
                  onDefaultCurrencyChange={onDefaultCurrencyChange}
                  onDecimalCommaChange={onDecimalCommaChange}
                  onDefaultTranscriptionLanguageChange={onDefaultTranscriptionLanguageChange}
                  onSaveButtonClick={onSaveBusinessDetailsButtonClick}
                  isLoading={isLoading}
                />

                <ERPOverview businessSettings={businessSettings} />

                <ERPModels businessSettings={businessSettings} />
              </div>
            </ScrollAreaWrapper>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SettingsPage;
