class BusinessTime {
  from: string;

  to: string;
}

class ErpConnectionSftpAuth {
  type: 'password';

  password: string;
}

class ErpConnectionSftp {
  host: string;

  user: string;

  auth: ErpConnectionSftpAuth;
}

class ErpSyncLazy {
  interval: string;
}

class ErpSyncedEntityConfig {
  enabled: boolean;

  direction: 'from_erp' | 'to_erp' | 'from_to_erp';

  interval: string;

  softDeleteExcludedDocuments: boolean;

  matcherId: string;

  encoding: string;

  inputSearchDirectory: string;

  inputFilenameRegex: string;

  outputFormat: string;

  outputDirectory: string;

  outputFilenameFormat: string;

  outputFilenameFormatArgs: string[];

  outputMaxRecordsPerFile: number;
}

class ErpSyncSettings {
  type: 'lazy';

  lazy?: ErpSyncLazy;

  syncedEntities: { [k: string]: ErpSyncedEntityConfig };
}

enum ErpConnectionType {
  Abacus = 'abacus',
  DynamicsBC = 'dynamics_bc',
  SAPByd = 'sap_byd',
  SFTP = 'sftp',
}

class AbacusConnectorConfig {
  baseUrl: string;

  oAuthBaseUrl: string;

  clientId: string;

  grantType: string;
}

class DynamicsBCConnectorConfig {
  baseUrl: string;

  apiVersion: string;

  extensionAPIVersion: string;

  oAuthBaseUrl: string;

  clientId: string;

  grantType: string;

  scopes: string[];

  companyId: string;
}

type SAPBydConnectorConfig = {
  instanceUrl: string;

  endpoints: Endpoints;

  authType: AuthType;

  basicAuth: BasicAuth | null;

  importBatchSize?: number;
};

enum AuthType {
  BASIC = 'basic',
}

type BasicAuth = {
  username: string;
  password: string;
};

type Endpoints = {
  customers?: string;

  materials?: string;

  salesOrders?: string;
};

class ErpConnectionSettings {
  type: ErpConnectionType;

  sftp?: ErpConnectionSftp;

  abacus?: AbacusConnectorConfig;

  dynamicsBc?: DynamicsBCConnectorConfig;

  sapByd?: SAPBydConnectorConfig;

  syncSettings?: ErpSyncSettings;
}

class BusinessSettings {
  defaultCurrency: string;

  businessTimes: BusinessTime[];

  decimalComma: ',' | '.' | '';

  defaultTranscriptionLanguageCode: string;

  erpConnectionSettings: ErpConnectionSettings;
}

export { AuthType, BusinessSettings, ErpConnectionType };
