import {
  useCallback, useMemo, useRef,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { WorkflowRetrySidebarWrapper } from 'components/wrapper/WorkflowRetrySidebarWrapper';
import { Workflow, WorkflowRun } from 'models/Workflow';
import { useRetryWorkflow } from 'hooks/useRetryWorkflow';
import { ROUTES } from 'config/routes';
import { Message } from 'models/Message';
import { Business } from 'models/Business';
import Content from './Content';

interface Props {
  messages: Message[];
  customer: Business | null;
  isMessagesLoading: boolean;
  isCustomerLoading: boolean;
}

const ProcessMessage = observer(({
  messages,
  customer,
  isMessagesLoading,
  isCustomerLoading,
}: Props) => {
  const navigate = useNavigate();

  // Assuming its an order
  const onOrderWorkflowRunRetried = useCallback((workflowRunId: string) => {
    navigate(ROUTES.PROCESS_ORDER_DRAFT_BY_ID(workflowRunId));
  }, [navigate]);

  const { retryWorkflow, pendingLROs } = useRetryWorkflow({ onOrderWorkflowRunRetried });
  const workflowRetrySidebarWrapperRef = useRef<any>(null);

  const pendingId = messages[0]?.id;

  const pendingMessageRetries = useMemo(() => new Set(
    Array.from(pendingLROs.values()).map((lro) => lro.data as string),
  ), [pendingLROs]);
  const isMessageRetrying = useMemo(() => (
    pendingMessageRetries.has(pendingId as string)
  ), [pendingMessageRetries, pendingId]);

  const onOpenRetryWorkflowSidebarButtonClick = useCallback(() => {
    if (workflowRetrySidebarWrapperRef.current) {
      workflowRetrySidebarWrapperRef.current.onRetryWorkflowButtonClick();
    }
  }, []);

  const onRetryButtonClick = (
    selectedWorkflow: string, workflowRun: WorkflowRun, transcriptionLanguage?: string,
  ) => {
    retryWorkflow(selectedWorkflow, workflowRun, pendingId, transcriptionLanguage);
  };

  const workflowRunId = useMemo(() => {
    if (!messages[0] || !messages[0].workflowRuns) return null;

    return messages[0].workflowRuns[Workflow.Other];
  }, [messages]);

  if (!workflowRunId) {
    return (
      <Content
        messages={messages}
        customer={customer}
        isMessagesLoading={isMessagesLoading}
        isCustomerLoading={isCustomerLoading}
        isMessageRetrying={isMessageRetrying}
      />
    );
  }

  return (
    <WorkflowRetrySidebarWrapper
      ref={workflowRetrySidebarWrapperRef}
      workflowRunId={workflowRunId}
      onRetryWorkflow={onRetryButtonClick}
    >
      <Content
        messages={messages}
        customer={customer}
        isMessagesLoading={isMessagesLoading}
        isCustomerLoading={isCustomerLoading}
        isMessageRetrying={isMessageRetrying}
        onOpenRetryWorkflowSidebarButtonClick={
            onOpenRetryWorkflowSidebarButtonClick
          }
      />
    </WorkflowRetrySidebarWrapper>
  );
});

export default ProcessMessage;
