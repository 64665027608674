import { THRESHOLD } from 'features/order/components/process-order-draft/form-elemts/ProductSelect/constants';
import { ContentTheme } from 'components/ui/Select';
import { LlmScore } from 'features/order/models/Order';
import { Product, Unit } from '../models/Product';

const getUnits = (product: Product): string[] => {
  if (!product) return [];

  // First check if sales units are available (new version), otherwise return units
  if (product.salesUnits && product.salesUnits.length > 0) {
    return product.salesUnits.map((unit) => unit.symbol);
  }
  return product.units || [];
};

const convertQuantity = (
  prevQuantity: number,
  prevUnit: Unit,
  newUnit: Unit,
): number => {
  if (!prevQuantity || !prevUnit || !newUnit) return prevQuantity;

  if (!prevUnit.baseConversionFactor || !newUnit.baseConversionFactor) return prevQuantity;

  const prevUnitFactor = prevUnit.baseConversionFactor;
  const newUnitFactor = newUnit.baseConversionFactor;

  return prevQuantity * (prevUnitFactor / newUnitFactor);
};

const getContentThemeAndTooltip = (
  product: Product,
  autoMatchedId: string,
  score?: number,
  llmScore?: string,
): { theme: ContentTheme; tooltip: string } => {
  let contentTheme = ContentTheme.STANDARD;
  let customLabelTooltip = '';

  // if there is llmScore, use it instead of score
  if (llmScore === LlmScore.BEST) {
    contentTheme = ContentTheme.AIInfo;
    customLabelTooltip = 'Adam has matched this product';
  } else if (llmScore === LlmScore.ALMOST_SURE) {
    contentTheme = ContentTheme.WARNING;
    customLabelTooltip = 'Adam is almost sure about this match';
  } else if (llmScore === LlmScore.CONFIDENT) {
    contentTheme = ContentTheme.WARNING;
    customLabelTooltip = 'Adam is confident about this match';
  } else if (llmScore === LlmScore.NOT_CONFIDENT) {
    contentTheme = ContentTheme.WARNING;
    customLabelTooltip = 'Adam is not confident about this match';
  } else if (llmScore === LlmScore.NOT_SURE) {
    contentTheme = ContentTheme.WARNING;
    customLabelTooltip = 'Adam is not sure about this match';
  }

  if (score && score < THRESHOLD) {
    contentTheme = ContentTheme.AIWarning;
    customLabelTooltip = 'Adam has matched this product, but the product might not be the correct product';
  } else if (product?.score && product?.score < THRESHOLD) {
    if (product?.id && product?.id === autoMatchedId) {
      contentTheme = ContentTheme.AIWarning;
      customLabelTooltip = 'Adam has matched this product, but the product might not be the correct product';
    } else {
      contentTheme = ContentTheme.WARNING;
    }
  } else if (product?.id && product?.id === autoMatchedId) {
    contentTheme = ContentTheme.AIInfo;
    customLabelTooltip = 'Adam has matched this product';
  }

  return { theme: contentTheme, tooltip: customLabelTooltip };
};

const getAdditionalInfo = (product: Product): string[] => {
  const additionalInfo: string[] = [];

  if (!product) return additionalInfo;

  if (product.provenanceCode) {
    additionalInfo.push(`provenance code: ${product.provenanceCode}`);
  }
  if (product.baseUnit?.symbol) {
    additionalInfo.push(`base unit: ${product.baseUnit.symbol}`);
  }
  if (product.salesUnits && product.salesUnits.length > 0) {
    additionalInfo.push(
      `sales units: ${product.salesUnits.map((unit) => unit.symbol).join(', ')}`,
    );
  }

  return additionalInfo;
};

export {
  getUnits,
  convertQuantity,
  getContentThemeAndTooltip,
  getAdditionalInfo,
};
