import Dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';
import { startOfToday } from 'date-fns';

import { intentToTagVariant } from 'helpers/enums';
import { isZeroTime } from 'helpers/dateTime';
import { Tag } from 'components/common/Tag';
import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';
import { decodeEntities } from 'utils/messageUtils';
import { Message } from 'models/Message';
import { Tooltip } from '@mantine/core';

interface Props {
  message: Message;
  selectedMessages: Message[];
  setSelectedMessages: (messages: Message[]) => void;
  onMessageRowClick: (message: Message) => void;
}

const formatDatetime = (message: Message) => {
  const now = startOfToday();
  if (isZeroTime(message.originalCreatedAt) && isZeroTime(message.createdAt)) {
    return '-';
  }

  const messageDatetime = Dayjs(message.originalCreatedAt || message.createdAt);

  if (messageDatetime.isSame(now, 'day')) {
    return messageDatetime.format('LT');
  }

  return messageDatetime.format('MMM D');
};

const MessageTableRow = ({
  message,
  selectedMessages,
  setSelectedMessages,
  onMessageRowClick,
}: Props) => (
  <tr
    key={message.id}
    className={twMerge(
      'cursor-pointer bg-white',
      selectedMessages.includes(message) || !isZeroTime(message.readAt)
        ? 'bg-gray-100'
        : 'hover:bg-gray-50',
    )}
    onClick={() => onMessageRowClick(message)}
  >
    <td className="relative px-7 sm:w-12 sm:px-xl">
      {selectedMessages.includes(message) && (
      <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
      )}
      <input
        type="checkbox"
        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
        value={message.id}
        checked={selectedMessages.includes(message)}
        onChange={(e) => setSelectedMessages(
          e.target.checked
            ? [...selectedMessages, message]
            : selectedMessages.filter((p) => p !== message),
        )}
        onClick={(e) => e.stopPropagation()}
      />
    </td>
    <td className="px-lg">
      <div className="items-center">
        {/* Business icon that might be added later */}
        {/* <div className="h-11 w-11 flex-shrink-0">
          <div className="h-11 w-11 rounded-full bg-indigo-50 text-indigo-500 flex items-center justify-center">
            <p className="text-2xl font-bold">{message.businessSentByInfo?.name?.slice(0, 2).toUpperCase()}</p>
          </div>
        </div> */}
        <div className="text-gray-900">
          {message.businessSentByInfo?.name}
        </div>
      </div>
    </td>
    <td className="px-lg">
      <div className="flex items-center gap-1">
        {message.intents && message.intents.length > 0 && (
        <>
          <Tag
            tagTitle={message.intents[0] || '-'}
            isTagLoading={false}
            className="static"
            tagVariant={intentToTagVariant(message.intents[0])}
            hideCircle
          />
          {message.intents.length > 1 && (
          <Tooltip
            label={(
              <div className="flex flex-col gap-2">
                {message.intents?.map((intent) => (
                  <Tag
                    key={intent}
                    tagTitle={intent || '-'}
                    isTagLoading={false}
                    className="static"
                    tagVariant={intentToTagVariant(intent)}
                    hideCircle
                  />
                ))}
              </div>
                  )}
            transitionProps={{ transition: 'pop' }}
            bg="white"
            position="bottom"
            styles={{
              tooltip: {
                boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
              },
            }}
          >
            <div className="flex aspect-square h-fit items-center justify-center rounded-full border border-gray-200 px-1 text-xxs text-gray-500">
              +
              {message.intents.length - 1}
            </div>
          </Tooltip>
          )}
        </>
        )}
      </div>
    </td>
    <td className="w-full px-lg py-smd text-sm text-gray-500">
      <div className="font-bold text-gray-900">
        {decodeEntities(message.context?.subject) || 'No subject'}
      </div>
      <div className="max-w-lg">
        <OverflowEllipsisParagraph maxLines={1}>
          <div className="text-gray-500">{decodeEntities(message.message)}</div>
        </OverflowEllipsisParagraph>
      </div>
    </td>
    <td className="whitespace-nowrap px-lg py-smd pr-7 text-sm text-gray-500">
      {formatDatetime(message)}
    </td>
  </tr>
);

export default React.memo(MessageTableRow);
