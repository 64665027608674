import {
  memo,
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDebouncedState } from '@mantine/hooks';

import { ProductWithQuantity } from 'features/order/models/Order';
import { Product } from 'models/Product';

import { ContentTheme, Select, SelectOption } from 'components/ui/Select';
import {
  QuerySearchType,
  useFetchProducts,
} from 'hooks/fetch/useFetchProducts';
import {
  getAdditionalInfo,
  getContentThemeAndTooltip,
} from 'helpers/product';
import { ProductSortingColumn } from 'types/product';

type Params = {
  businessId: string;
  searchQuery?: string;
  useNameOrIDOrSKUSearch?: boolean;
  sortingColumn?: ProductSortingColumn;
  filterEnabledProducts?: boolean;
  reset?: boolean;
};

interface Props {
  businessId: string;
  sortingColumn: ProductSortingColumn;
  filterEnabledProducts: boolean;
  productWithQuantity: ProductWithQuantity;
  onProductChange: (product: Product) => void;
  label?: string;
}

const MemoizedSelect = memo(({
  label,
  selectedProduct,
  productsOptions,
  isLoading,
  onProductsSelectChange,
  onProductsSearchChange,
  onProductsSelectOptionsScrolledEnd,
  onDropDownOpen,
}: {
  label: string;
  selectedProduct: SelectOption;
  productsOptions: SelectOption[];
  isLoading: boolean;
  onProductsSelectChange: (option: SelectOption) => void;
  onProductsSearchChange: (value: string) => void;
  onProductsSelectOptionsScrolledEnd: (value: string) => void;
  onDropDownOpen: () => void;
}) => (
  <Select
    label={label}
    searchPlaceholder="Type here to search products..."
    dropdownPlaceHolder="Type a product name or keyword to search"
    selectedOption={selectedProduct}
    options={productsOptions}
    isLoading={isLoading}
    isExternalSearchEnabled
    onSelectionChange={onProductsSelectChange}
    onSearchPerformed={onProductsSearchChange}
    onScrolledEnd={onProductsSelectOptionsScrolledEnd}
    onDropdownOpen={onDropDownOpen}
    position="bottom"
    middlewares={{ flip: false, shift: false }}
  />
));

const ProductSelect = memo(({
  businessId,
  sortingColumn,
  filterEnabledProducts,
  label = '',
  productWithQuantity,
  onProductChange,
}: Props) => {
  const {
    products, isLoading, loadProducts, endReachedRef,
  } = useFetchProducts(
    { preventInitialFetch: true },
  );

  const [searchQuery, setSearchQuery] = useDebouncedState('', 300);

  const isFirstRender = useRef(true);
  const paramsRef = useRef<Params>({
    businessId,
    sortingColumn,
  });

  useEffect(() => {
    paramsRef.current.sortingColumn = sortingColumn;
  }, [sortingColumn]);

  useEffect(() => {
    paramsRef.current.filterEnabledProducts = filterEnabledProducts;
  }, [filterEnabledProducts]);

  useEffect(() => {
    paramsRef.current.businessId = businessId;
  }, [businessId]);

  const selectedProduct = useMemo<SelectOption>(() => {
    if (!productWithQuantity.product) {
      return {
        contentTheme: ContentTheme.WARNING,
        label: 'No similar products were found for the extracted product name. Click here to search.',
        value: '',
        tooltip: 'No similar products were found for the extracted product name. Click here to search.',
      };
    }

    const { theme: contentTheme, tooltip: customLabelTooltip } = getContentThemeAndTooltip(
      productWithQuantity.product,
      productWithQuantity?.autoMatchedId,
      productWithQuantity?.score,
    );

    const additionalInfo = getAdditionalInfo(productWithQuantity.product);

    return {
      visibleId: productWithQuantity.product?.idOrSku,
      label: productWithQuantity.product?.name,
      value: productWithQuantity.product?.id,
      flag: productWithQuantity.product?.disabled ? 'Disabled' : null,
      meta: productWithQuantity.product?.orderCount?.toString() || null,
      contentTheme,
      customLabelTooltip,
      additionalInfo: (
        <div>
          {additionalInfo.map((info) => (
            <p key={info}>{info}</p>
          ))}
        </div>
      ),
    };
  }, [
    productWithQuantity.product,
    productWithQuantity.autoMatchedId,
    productWithQuantity.score,
  ]);

  const productsOptions = useMemo(
    () => products.map((p) => {
      const {
        theme: contentTheme,
        tooltip: customLabelTooltip,
      } = getContentThemeAndTooltip(p, productWithQuantity?.autoMatchedId);

      const additionalInfo = getAdditionalInfo(p);

      return {
        visibleId: p.idOrSku,
        label: p.name,
        value: p.id,
        flag: p.disabled ? 'Disabled' : null,
        meta: p.orderCount?.toString() || null,
        metaDescription: `Orders number of this product: ${p.orderCount?.toString() || null}`,
        contentTheme,
        customLabelTooltip,
        additionalInfo: (
          <div>
            {additionalInfo.map((info) => (
              <p key={info}>{info}</p>
            ))}
          </div>
        ),
      };
    }),
    [products, productWithQuantity.autoMatchedId],
  );

  const onProductsSelectChange = useCallback(
    (option: SelectOption) => {
      onProductChange(
        products.find((p) => p.id === option.value),
      );
    },
    [onProductChange, products],
  );

  const onProductsSearchChange = useCallback(
    (value: string) => {
      const searchValue: string = value || '';
      if (!isLoading && searchValue !== '') {
        paramsRef.current.useNameOrIDOrSKUSearch = true;
        paramsRef.current.reset = true;
        setSearchQuery(searchValue);
      } else if (!isLoading && searchValue === '') {
        paramsRef.current.useNameOrIDOrSKUSearch = false;
        paramsRef.current.reset = true;
        setSearchQuery(productWithQuantity.name);
      }
    },
    [isLoading, productWithQuantity.name, setSearchQuery],
  );

  const onProductsSelectOptionsScrolledEnd = useCallback(
    (value: string) => {
      if (!isLoading && value !== '' && !endReachedRef.current) {
        loadProducts({
          searchQuery: value,
          customerId: paramsRef.current.businessId,
          querySearchType: QuerySearchType.Standard,
          sortingColumns: [paramsRef.current.sortingColumn],
          filterEnabledProducts: paramsRef.current.filterEnabledProducts,
          reset: false,
        });
      }
    },
    [endReachedRef, isLoading, loadProducts],
  );

  const onDropDownOpen = useCallback(() => {
    if (!isLoading && !products.length && productWithQuantity.name) {
      paramsRef.current.useNameOrIDOrSKUSearch = false;
      paramsRef.current.reset = false;
      setSearchQuery(productWithQuantity.name);
    }
  }, [isLoading, products.length, productWithQuantity.name, setSearchQuery]);

  useEffect(() => {
    if (isFirstRender.current || !paramsRef.current || !loadProducts) {
      isFirstRender.current = false;
      return;
    }

    loadProducts({
      searchQuery,
      customerId: paramsRef.current.businessId,
      querySearchType: paramsRef.current.useNameOrIDOrSKUSearch
        ? QuerySearchType.Standard
        : QuerySearchType.Embedding,
      sortingColumns: [paramsRef.current.sortingColumn],
      filterEnabledProducts: paramsRef.current.filterEnabledProducts,
      reset: paramsRef.current.reset,
    });
  }, [loadProducts, searchQuery]);

  return (
    <MemoizedSelect
      label={label}
      selectedProduct={selectedProduct}
      productsOptions={productsOptions}
      isLoading={isLoading}
      onProductsSelectChange={onProductsSelectChange}
      onProductsSearchChange={onProductsSearchChange}
      onProductsSelectOptionsScrolledEnd={onProductsSelectOptionsScrolledEnd}
      onDropDownOpen={onDropDownOpen}
    />
  );
});

export default ProductSelect;
