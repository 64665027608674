import { Business } from '../models/Business';

const getAdditionalInfo = (customer: Business): string[] => {
  const additionalInfo: string[] = [];

  if (!customer) return additionalInfo;

  if (customer.name) {
    additionalInfo.push(`Name: ${customer.name}`);
  }

  if (customer.nameSupplement) {
    additionalInfo.push(`Name supplement: ${customer.nameSupplement}`);
  }

  if (customer.externalId) {
    additionalInfo.push(`ID: ${customer.externalId}`);
  }

  if (customer.shortName) {
    additionalInfo.push(`Short name: ${customer.shortName}`);
  }

  if (customer.address) {
    additionalInfo.push(`Address: ${customer.address}`);
  }

  if (customer.emails) {
    customer.emails.forEach((email) => {
      additionalInfo.push(`Email: ${email}`);
    });
  }

  if (customer.phones) {
    customer.phones.forEach((phone) => {
      additionalInfo.push(`Phone: ${phone.number}`);
    });
  }

  if (customer.customer?.notes) {
    customer.customer.notes.forEach((note) => {
      additionalInfo.push(`Note: ${note.note}`);
    });
  }

  return additionalInfo;
};

export { getAdditionalInfo };
