import { v4 as uuidv4 } from 'uuid';

import { Business } from '../../../models/Business';
import { Price, Product, Unit } from '../../../models/Product';
import { TypeSpec } from '../../../models/Schema';

const convertibleUnits = ['G', 'GR', 'GRAM', 'K', 'KG', 'KILO'];

class Order {
  id: string;

  number?: string;

  externalId?: string;

  supplier: Business;

  customer: Business;

  products: ProductWithQuantity[];

  totalPrice: Price;

  requestedDeliveryTime: string;

  orderDate: string;

  autoMatchedRequestedDeliveryTime: string;

  status: OrderStatus;

  statusTrails: OrderStatusTrail[];

  deliveryStatus: OrderDeliveryStatus;

  deliveryStatusTrails: OrderDeliveryStatusTrail[];

  createdAt: string;

  lastErpSyncAt?: string;

  createdBy: string;

  createdByUser: string;

  updatedAt: string;

  isDraft: boolean;

  isExported: boolean;

  assigneeId: string;

  draft: {
    comment: string;
  };

  groupId: string;

  isGroupSibling: boolean;

  freeFields?: Record<string, any>;

  typeSpecs: TypeSpec[];

  // UI only to store prompt id
  promptId?: string;

  createdByWorkflowRunId: string;

  // UI only param to check whether we need to save order changes
  didChangeMade: boolean;

  // UI only param to check whether we need to create order instead of saving them
  isCreated: boolean;
}

enum OrderStatus {
  New = 'NEW',
  Confirmed = 'CONFIRMED',
  Rejected = 'REJECTED',
  TimedOut = 'TIMED_OUT',
}

enum OrderDeliveryStatus {
  Dispatched = 'DISPATCHED',
  Delivered = 'DELIVERED',
}

class OrderStatusTrail {
  createdAt: string;

  deliveryEta: string;

  message: string;

  status: OrderStatus;
}

class OrderDeliveryStatusTrail {
  createdAt: string;

  deliveryEta: string;

  status: OrderStatus;

  message: string;
}

enum LlmScore {
  BEST = 'BEST',
  ALMOST_SURE = 'ALMOST_SURE',
  SURE = 'SURE',
  CONFIDENT = 'CONFIDENT',
  NOT_CONFIDENT = 'NOT_CONFIDENT',
  NOT_SURE = 'NOT_SURE',
}

class ProductWithQuantity {
  id: string;

  positionId?: string; // Used to match the keyword and product

  name: string;

  quantity: number;

  product: Product;

  unit: Unit;

  price: Price;

  comment: string;

  score?: number;

  llmScore?: LlmScore;

  autoMatched: boolean;

  autoMatchedId?: string;

  autoMatchedIdOrSku?: string;

  autoMatchedUnit?: Unit;

  defaultUnitConversionFactor: number | null;

  freeFields?: Record<string, any>;

  // Used by ui to perform a correct draft search without changing displayed name
  updatedName?: string;

  // Error messages related to the product
  errors?: Record<string, string>;

  // User can confirm that this product is already checked
  confirmed?: boolean;

  // Ui only param to distinguish product with same name
  uiId: string;

  constructor(data: Partial<ProductWithQuantity>) {
    this.id = data.id;
    this.name = data.name;
    this.quantity = data.quantity;
    this.product = data.product;
    this.unit = data.unit;
    this.price = data.price;
    this.comment = data.comment;
    this.score = data.score;
    this.autoMatched = data.autoMatched;
    this.autoMatchedId = data.autoMatchedId;
    this.autoMatchedIdOrSku = data.autoMatchedIdOrSku;
    this.autoMatchedUnit = data.autoMatchedUnit;
    this.defaultUnitConversionFactor = data.defaultUnitConversionFactor;
    this.freeFields = data.freeFields;
    this.updatedName = data.updatedName;
    this.errors = data.errors;
    this.confirmed = data.confirmed;

    this.uiId = uuidv4();
  }
}

export {
  convertibleUnits,
  Order,
  ProductWithQuantity,
  OrderDeliveryStatus,
  OrderStatus,
  OrderDeliveryStatusTrail,
  LlmScore,
};
