export enum Environment {
  DEV_LOCAL = 'dev-local',
  DEV = 'dev',
  DEV_PILOT = 'dev-pilot',
  PROD = 'prod',
}

// Function to check if the environment is production-like
export function isEnvProdLike(): boolean {
  return ENVIRONMENT === Environment.PROD || ENVIRONMENT === Environment.DEV_PILOT;
}
