import {
  useCallback,
} from 'react';

import { FieldSpec, Schema } from 'models/Schema';
import { Model } from 'types/prompt';

import {
  isListFieldSpec, fieldSpecToModel,
} from 'helpers/schema';

import { ProductFields } from '../ProductFields';
import { CustomerFields } from '../CustomerFields';
import GroupedFieldsFieldItem from './GroupedFieldsFieldItem';

interface Props {
  fieldSpecs: FieldSpec[];
  setError?: (key: string, error: string) => void;
  obj: any;
  schema: Schema;
}

const GroupedFields = ({
  fieldSpecs,
  setError: _setError,
  obj,
  schema,
}: Props) => {
  const model = fieldSpecToModel(fieldSpecs[0]);

  const setError = useCallback((subKey: string, error: string) => {
    _setError?.(subKey, error);
  }, [_setError]);

  if (isListFieldSpec(fieldSpecs[0])) {
    switch (model) {
      case Model.PRODUCTS:
        return (
          <ProductFields
            fieldSpecs={fieldSpecs}
            setError={setError}
            obj={obj}
            schema={schema}
          />
        );
      default:
        return (
          <div>
            Unsupported model
          </div>
        );
    }
  }

  switch (model) {
    case Model.CUSTOMER:
      return (
        <CustomerFields
          fieldSpecs={fieldSpecs}
          setError={setError}
          obj={obj}
          schema={schema}
        />
      );
    default:
      return (
        <div className="space-y-4">
          {fieldSpecs.map((fieldSpec) => (
            <GroupedFieldsFieldItem
              key={fieldSpec.path}
              obj={obj}
              schema={schema}
              fieldSpec={fieldSpec}
              setError={setError}
            />
          ))}
        </div>
      );
  }
};

export default GroupedFields;
