import { Textarea } from '@mantine/core';

const CommentField = ({
  defaultComment,
  onTextAreaChange,
}: {
  defaultComment: string;
  onTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) => (
  <Textarea
    placeholder="Add some comment if needed..."
    defaultValue={defaultComment}
    label="Comment"
    minRows={2}
    maxRows={4}
    autosize
    autoComplete="off"
    className="pt-4"
    onChange={onTextAreaChange}
  />
);

export default CommentField;
