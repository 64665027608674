import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Overlay, Image as MantineImage } from '@mantine/core';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

import { twMerge } from 'tailwind-merge';

import { Message } from 'models/Message';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';

import { useParseFields } from 'hooks/useParseFields';
import { OCRKeyword } from 'models/Workflow';
import { Buttons } from './Buttons';
import { KonvaStage } from './KonvaStage';
import { Annotation, KonvaStageRef, Tool } from './KonvaStage/type';

interface Props {
  message: Message;

  selectedDocIndex: number;
  selectedDocPageIndex: number;
  selectedDocImgIndex: number;
  docImages: { url: string }[];

  setShow: (show: boolean) => void;
  onPreviousButtonClick: () => void;
  onNextButtonClick: () => void;
}

const ImageOverlay = ({
  selectedDocIndex,
  selectedDocPageIndex,
  selectedDocImgIndex,
  docImages,
  message,
  setShow,
  onPreviousButtonClick,
  onNextButtonClick,
}: Props) => {
  const { addNewParsedProducts, addNonProductFields, setAnnotations } = useProcessOrderContext();

  const { parseFields } = useParseFields();

  const [useMagicPen, setUseMagicPen] = useState(false);
  const [selectedTool, setSelectedTool] = useState<Tool>('Magic Pen');
  const [wheelSmoothStep, setWheelSmoothStep] = useState(0.003);

  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const konvaStageRef = useRef<KonvaStageRef>(null);

  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [naturalImageDimensions, setNaturalImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const scaleX = useMemo(() => imageDimensions.width / naturalImageDimensions.width,
    [imageDimensions, naturalImageDimensions],
  );
  const scaleY = useMemo(() => imageDimensions.height / naturalImageDimensions.height,
    [imageDimensions, naturalImageDimensions],
  );

  const handleImageLoad = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement>) => {
      const img = event.currentTarget;
      setImageDimensions({
        width: img.width,
        height: img.height,
      });
      setNaturalImageDimensions({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });

      if (containerRef.current && imageRef.current) {
        // if image is wider than container, set width to container width
        if (imageRef.current.clientWidth > containerRef.current.clientWidth) {
          imageRef.current.style.width = `${containerRef.current.offsetWidth}px`;
        }
        // if image is taller than container, set height to container height
        if (imageRef.current.clientHeight > containerRef.current.clientHeight) {
          imageRef.current.style.height = `${containerRef.current.offsetHeight}px`;
        }

        setTimeout(() => {
          setImageDimensions({
            width: imageRef.current.offsetWidth,
            height: imageRef.current.offsetHeight,
          });
        }, 200);
      }
    },
    [],
  );

  const sendScreenshot = (annotation: Annotation) => {
    const container = imageRef.current;

    // rescale the annotation to the image size
    const scaledAnnotation = {
      x: annotation.x * (naturalImageDimensions.width / imageDimensions.width),
      y:
        annotation.y * (naturalImageDimensions.height / imageDimensions.height),
      width:
        annotation.width
        * (naturalImageDimensions.width / imageDimensions.width),
      height:
        annotation.height
        * (naturalImageDimensions.height / imageDimensions.height),
    };

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set canvas dimensions to match the screenshot area
    canvas.width = scaledAnnotation.width;
    canvas.height = scaledAnnotation.height;

    // Draw the selected part of the image onto the canvas
    ctx.drawImage(
      container,
      scaledAnnotation.x,
      scaledAnnotation.y,
      scaledAnnotation.width,
      scaledAnnotation.height,
      0,
      0,
      scaledAnnotation.width,
      scaledAnnotation.height,
    );

    // convert base64 to file
    // parseFields([new File([screenshot], 'screenshot.png', { type: 'image/png' })]);
    canvas.toBlob((blob) => {
      if (blob) {
        konvaStageRef.current?.setIsLoading(annotation.key, true);
        parseFields([
          new File([blob], 'screenshot.png', { type: 'image/png' }),
        ]).then((data) => {
          const popupContents: string[] = [];

          const nonProductValues = addNonProductFields(data);
          popupContents.push(...nonProductValues);

          addNewParsedProducts(data)
            .then((newProducts) => {
              if (newProducts.length === 0) return;

              newProducts.forEach((product) => {
                popupContents.push(product.name);
              });

              konvaStageRef.current?.addProductUiIds(
                annotation.key,
                newProducts.map((product) => product.uiId),
              );
            })
            .finally(() => {
              konvaStageRef.current?.setIsLoading(annotation.key, false);
              konvaStageRef.current?.addPopup(
                annotation.key,
                <ul className="list-disc list-inside">
                  {popupContents.map((content) => (
                    <li key={content}>{content}</li>
                  ))}
                </ul>,
              );
            });
        }).catch(() => {
          konvaStageRef.current?.setIsLoading(annotation.key, false);
        });
      }
    });
  };

  const keywordPatches = useMemo(() => {
    const patches: any[] = [];
    const keywords = message.context?.workflowOrder?.ocrKeywords || [];

    keywords
      .filter((keyword: OCRKeyword) => keyword.docIndex === selectedDocIndex && keyword.pageIndex === selectedDocPageIndex)
      .forEach((keyword: OCRKeyword, index: number) => {
        const geometry = keyword.geometry;

        if (geometry.length % 2 !== 0) {
          console.error('polygonal geometry length is not even');
          return;
        }

        const points: number[][] = geometry.reduce((acc, x, i, arr) => {
          if (i % 2 === 0) {
            acc.push([x, arr[i + 1]]);
          }
          return acc;
        }, []);

        patches.push({
          id: `${keyword.docIndex}:${index}`,
          fieldId: keyword.fieldId,
          keyword: keyword.word,
          points,
        });
      });

    return patches;
  }, [selectedDocIndex, selectedDocPageIndex, message.context?.workflowOrder?.ocrKeywords]);

  const rescalePoint = useCallback((point: number[]) => {
    const [x, y] = point;
    return [
      x * scaleX,
      y * scaleY,
    ];
  }, [scaleX, scaleY]);

  const rescaledKeywordAnnotations = useMemo(() => {
    const pointsToXYWH = (points: number[][]) => {
      // find the min and max x and y
      const minX = Math.min(...points.map((point) => point[0]));
      const minY = Math.min(...points.map((point) => point[1]));
      const maxX = Math.max(...points.map((point) => point[0]));
      const maxY = Math.max(...points.map((point) => point[1]));
      return [minX, minY, maxX - minX, maxY - minY];
    };

    return keywordPatches.map((patch) => ({
      ...patch,
      points: patch.points.map(rescalePoint),
    })).map((patch) => {
      const [x, y, width, height] = pointsToXYWH(patch.points);
      return {
        key: patch.id,
        x,
        y,
        width,
        height,
        popupContent: patch.keyword,
        productUiIds: [patch.fieldId],
      };
    });
  }, [keywordPatches, rescalePoint]);

  const onWheelStart = (ref: ReactZoomPanPinchRef, event: WheelEvent) => {
    if (!event.ctrlKey) {
      setWheelSmoothStep(0.003);
    } else {
      setWheelSmoothStep(0.02);
    }
  };

  useEffect(() => {
    setAnnotations(
      `${selectedDocImgIndex}-${selectedDocPageIndex}`,
      rescaledKeywordAnnotations,
    );
  }, [rescaledKeywordAnnotations, selectedDocImgIndex, selectedDocPageIndex, setAnnotations]);

  return (
    <div className="absolute left-0 top-0 z-[10] h-full w-full">
      <div className="relative h-full w-full">
        {/* Overlay */}
        <Overlay
          bg="#EBEBED"
          opacity={0.4}
          onClick={() => setShow(false)}
          zIndex={10}
        />

        <div className="absolute inset-0 flex h-full w-full items-center justify-center">
          <TransformWrapper
            onWheelStart={onWheelStart}
            wheel={{
              smoothStep: wheelSmoothStep,
            }}
            disabled={useMagicPen && selectedTool !== 'Hand'}
          >
            {({ zoomIn, zoomOut }) => (
              <div className="flex h-full w-full items-center justify-center space-x-2 py-2">
                <button
                  type="button"
                  className={twMerge(
                    'z-[20] flex aspect-square w-8 items-center justify-center rounded-full border border-neutral-50 bg-white hover:bg-slate-50/80',
                    (docImages?.length === 1 || selectedDocImgIndex === 0)
                      && 'invisible',
                  )}
                  onClick={onPreviousButtonClick}
                >
                  <ChevronLeftIcon className="aspect-square w-5" />
                </button>
                <div className="z-[20] flex max-h-full max-w-full flex-col overflow-hidden rounded-xl border border-neutral-50 bg-white">
                  <div className="flex flex-1 flex-col items-center justify-center overflow-hidden p-2">
                    <TransformComponent
                      contentClass="flex flex-col flex-1 overflow-hidden justify-center items-center"
                      wrapperClass="flex flex-col flex-1 overflow-hidden justify-center items-center"
                    >
                      <div className="relative flex flex-1 flex-col overflow-hidden">
                        <div
                          ref={containerRef}
                          className="flex-1 overflow-hidden"
                        >
                          <MantineImage
                            ref={imageRef}
                            alt=""
                            src={docImages[selectedDocImgIndex].url}
                            onLoad={handleImageLoad}
                            className="h-full w-full cursor-move object-contain"
                            crossOrigin="use-credentials"
                          />

                          <KonvaStage
                            ref={konvaStageRef}
                            annotationsKey={`${selectedDocImgIndex}-${selectedDocPageIndex}`}
                            imageDimensions={imageDimensions}
                            scaleX={scaleX}
                            scaleY={scaleY}
                            useMagicPen={useMagicPen}
                            selectedTool={selectedTool}
                            sendScreenshot={sendScreenshot}
                          />
                        </div>
                      </div>
                    </TransformComponent>
                  </div>
                  <Buttons
                    mode={useMagicPen ? 'magic-pen' : 'default'}
                    defaultProps={{
                      zoomIn,
                      zoomOut,
                      onUseMagicPenClick: () => setUseMagicPen(true),
                    }}
                    magicPenProps={{
                      selectedTool,
                      setSelectedTool,
                      onCancelClick: () => setUseMagicPen(false),
                    }}
                  />
                </div>
                <button
                  type="button"
                  className={twMerge(
                    'z-[20] flex aspect-square w-8 items-center justify-center rounded-full border border-neutral-50 bg-white hover:bg-slate-50/80',
                    (docImages?.length === 1
                      || selectedDocImgIndex === docImages.length - 1)
                      && 'invisible',
                  )}
                  onClick={onNextButtonClick}
                >
                  <ChevronRightIcon className="aspect-square w-5" />
                </button>
              </div>
            )}
          </TransformWrapper>
        </div>
      </div>
    </div>
  );
};

export default ImageOverlay;
