/* eslint-disable react/prop-types */
import { memo, useCallback } from 'react';
import { FieldSchema, StandardFieldType } from 'models/Schema';
import { RequestedDeliveryTimePicker } from 'features/order/components/process-order-draft/form-elemts/RequestedDeliveryTimePicker';
import { RequestedDeliveryTimePickerProps } from 'features/order/components/process-order-draft/form-elemts/RequestedDeliveryTimePicker/types';
import { UnitSelect, UnitSelectProps } from 'features/order/components/process-order-draft/form-elemts/UnitSelect';
import { IdOrSkuInput } from 'features/order/components/process-order-draft/form-elemts/IdOrSkuInput';
import { UniversalField, UniversalFieldProps } from '../UniversalField';

interface Props {
  fieldSchema: FieldSchema;
  setError?: (key: string, error: string) => void;
  props: UniversalFieldProps | UnitSelectProps | RequestedDeliveryTimePickerProps;
}

const StandardField = memo(({
  fieldSchema, setError, props,
}: Props) => {
  const { label } = props as UniversalFieldProps | UnitSelectProps;

  const {
    productWithQuantity,
    onUnitChange,
  } = props as UnitSelectProps;

  const {
    type,
    value,
    size,
    itemStyle,
    itemClassName,
    placeHolder,
    disabled,
    readOnly,
    shouldScroll,
    onValueChange,
  } = props as UniversalFieldProps;

  const {
    requestedDeliveryTime,
    autoMatchedRequestedDeliveryTime,
    onDeliveryDateTimeChange,
  } = props as RequestedDeliveryTimePickerProps;

  const _setError = useCallback((err_key: string, error: string) => {
    setError?.(err_key, error);
  }, [setError]);

  const setRequestedDeliveryTimeError = useCallback((error: string) => {
    _setError('orderDeliveryDateTime', error);
  }, [_setError]);

  const setUnitError = useCallback((error: string) => {
    _setError(`unit-${productWithQuantity?.uiId}`, error);
  }, [_setError, productWithQuantity?.uiId]);

  const productQuantityError = useCallback((error: string) => {
    _setError(`quantity-${productWithQuantity?.uiId}`, error);
  }, [_setError, productWithQuantity?.uiId]);

  switch (fieldSchema.standardFieldType) {
    case StandardFieldType.StandardFieldTypeOrderNumber:
      return (
        <UniversalField
          type={type}
          label={label}
          value={value}
          size={size}
          itemStyle={itemStyle}
          itemClassName={itemClassName}
          placeHolder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          shouldScroll={shouldScroll}
          onValueChange={onValueChange}
        />
      );
    case StandardFieldType.StandardFieldTypeProductUnit:
      return (
        <UnitSelect
          size="xs"
          productWithQuantity={productWithQuantity}
          label={label}
          onUnitChange={onUnitChange}
          setError={setUnitError}
        />
      );
    case StandardFieldType.StandardFieldTypeOrderDeliveryDateTime:
      return (
        <RequestedDeliveryTimePicker
          requestedDeliveryTime={requestedDeliveryTime}
          autoMatchedRequestedDeliveryTime={autoMatchedRequestedDeliveryTime}
          onDeliveryDateTimeChange={onDeliveryDateTimeChange}
          setError={setRequestedDeliveryTimeError}
        />
      );
    case StandardFieldType.StandardFieldTypeProductIdOrSku:
      return (
        <IdOrSkuInput
          label={label}
          productWithQuantity={productWithQuantity}
          onValueChange={onValueChange}
        />
      );
    case StandardFieldType.StandardFieldTypeProductQuantity:
      return (
        <UniversalField
          type={type}
          label={label}
          value={value}
          size={size}
          required
          itemStyle={itemStyle}
          itemClassName={itemClassName}
          placeHolder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          validation="required"
          shouldScroll={shouldScroll}
          onValueChange={onValueChange}
          setError={productQuantityError}
        />
      );
    default:
      return (
        <UniversalField
          type={type}
          label={label}
          value={value}
          size={size}
          itemStyle={itemStyle}
          itemClassName={itemClassName}
          placeHolder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          shouldScroll={shouldScroll}
          onValueChange={onValueChange}
        />
      );
  }
});

export default StandardField;
