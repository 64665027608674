import {
  useCallback,
  Fragment,
  useEffect,
  useRef,
  useState,
  FormEvent,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { Button } from 'components/ui/Button';
import { Page } from 'components/layout/Page/Page';
import { ProductSearchBar } from 'components/product/ProductSearchBar';
import { ProductsTable } from 'components/product/ProductsTable';
import { ThreeDots } from 'components/ThreeDots';
import { Currency, Product } from 'models/Product';
import { genericErrorFeedback } from 'helpers/errors';
import { httpGet, httpGetBlob, httpPatch } from 'helpers/xhr';
import { getUnits } from 'helpers/product';
import { Tooltip } from '@mantine/core';

type ProductEditDialogProp = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  products: Product[];
  setProducts: React.Dispatch<React.SetStateAction<Product[]>>;
  idx: number;
};

const ProductEditDialog = ({
  open,
  setOpen,
  products,
  setProducts,
  idx,
}: ProductEditDialogProp) => {
  const [product, setProduct] = useState<Product>(products[idx]);
  const [loading, setLoading] = useState(false);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    httpPatch(`/products/${product.id}`, {
      ...product,
      units: getUnits(product).filter((item) => item !== ''),
    })
      .then((response) => {
        const entries = response.data || [];
        const temp = [...products];
        temp[idx] = entries;
        setProducts(temp);

        setOpen(false);
      })
      .catch(genericErrorFeedback)
      .finally(() => {});

    setLoading(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-lg text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="hidden-scrollbar relative transform overflow-hidden rounded-lg bg-white px-lg pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-h-[80vh] sm:w-full sm:max-w-[50vw] sm:overflow-scroll sm:p-xl">
                <form onSubmit={onSubmit}>
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-lg font-semibold leading-7 text-gray-900">
                      Product Information
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      Edit and push the save button.
                    </p>

                    <div className="mt-7 grid grid-cols-1 gap-x-xl gap-y-2xl sm:grid-cols-8">
                      <div className="sm:col-span-8">
                        <label
                          htmlFor="product-name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Product Name
                          <div className="mt-2">
                            <input
                              type="text"
                              name="product-name"
                              id="product-name"
                              value={product.name}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  name: e.target.value,
                                });
                              }}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                            />
                          </div>
                        </label>
                      </div>

                      <div className="sm:col-span-4">
                        <label
                          htmlFor="sku"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          ID or SKU
                          <div className="mt-2">
                            <input
                              type="text"
                              name="sku"
                              id="sku"
                              value={product.idOrSku}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  idOrSku: e.target.value,
                                });
                              }}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                            />
                          </div>
                        </label>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="price"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Price
                          <div className="mt-2">
                            <input
                              id="price"
                              name="price"
                              type="number"
                              value={product.price.amount}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  price: {
                                    ...product.price,
                                    amount:
                                      e.target.value !== ''
                                        ? parseInt(e.target.value, 10)
                                        : 0,
                                  },
                                });
                              }}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                            />
                          </div>
                        </label>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="currency"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Currency
                          <div className="mt-2">
                            <select
                              id="currency"
                              name="currency"
                              value={product.price.currency}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  price: {
                                    ...product.price,
                                    currency:
                                      e.target.value === 'CHF'
                                        ? Currency.Chf
                                        : Currency.Eur,
                                  },
                                });
                              }}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                              <option>CHF</option>
                              <option>EUR</option>
                            </select>
                          </div>
                        </label>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="min-order-qty"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Min Order Qty
                          <div className="mt-2">
                            <input
                              id="min-order-qty"
                              name="min-order-qty"
                              type="number"
                              value={product.minOrderQty}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  minOrderQty:
                                    e.target.value !== ''
                                      ? parseInt(e.target.value, 10)
                                      : 0,
                                });
                              }}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                            />
                          </div>
                        </label>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="unit"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Unit(s)
                          <div className="mt-2">
                            <input
                              id="unit"
                              name="unit"
                              type="text"
                              value={getUnits(product).join(',')}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  units: e.target.value.split(','),
                                });
                              }}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                            />
                          </div>
                        </label>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="category"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Category
                          <div className="mt-2">
                            <input
                              id="category"
                              name="category"
                              type="text"
                              value={product.category}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  category: e.target.value,
                                });
                              }}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                            />
                          </div>
                        </label>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="disaled"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Disabled
                          <div className="mt-2">
                            <select
                              id="disabled"
                              name="disabled"
                              value={product.disabled ? 'True' : 'False'}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  disabled: e.target.value === 'True',
                                });
                              }}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                              <option>True</option>
                              <option>False</option>
                            </select>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 flex space-x-2 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border-2 border-gray-300 px-slg py-smd text-sm font-semibold text-black shadow-sm"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex w-full items-center justify-center rounded-md bg-primary-500 px-slg py-smd text-sm font-semibold text-white shadow-sm hover:bg-primary-600"
                    >
                      {loading ? (
                        <div>
                          <ThreeDots className="text-white before:text-white after:text-white" />
                        </div>
                      ) : (
                        <>Save</>
                      )}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

interface ProductsListProps {
  navigateToProductsImportList: () => void;
}

interface GetProductRequestParam {
  category?: string;
  query?: string;
  query_search_type?: string;
}

const ProductsListPage = ({ navigateToProductsImportList }: ProductsListProps) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('All Categories');
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState<number>(null);
  const [requestParam, setRequestParam] = useState<GetProductRequestParam>({});
  const productsPaginationCursor = useRef();

  const onImportClick = useCallback(() => {
    navigateToProductsImportList();
  }, [navigateToProductsImportList]);

  const onSearchClicked = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const query = formData.get('search_query');

    const params: GetProductRequestParam = {};
    if (selectedCategory !== 'All Categories') {
      params.category = selectedCategory;
    }
    if (query) {
      params.query = query.toString();
    }

    setRequestParam(params);

    httpGet('/products', { params })
      .then((response) => {
        const e = response.data.result || [];
        if (response.data.cursor === '') {
          setIsLoading(false);
        }
        setProducts(e);
        productsPaginationCursor.current = response.data.cursor;
      })
      .catch(genericErrorFeedback('Error loading products'))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onExportClicked = useCallback(() => {
    setIsLoading(true);
    httpGetBlob('/products/export')
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'products.csv';
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?(.+)?"/);
          if (filenameMatch.length === 2) {
            filename = filenameMatch[1];
          }
        }
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch(genericErrorFeedback('Error loading products'))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const openDialog = (idx: number) => {
    setSelectedIdx(idx);
    setShowDialog(true);
  };

  const onScrolledEnd = () => {
    if (!isLoading) {
      httpGet('/products', {
        params: { ...requestParam, cursor: productsPaginationCursor.current },
      })
        .then((response) => {
          const e = response.data.result || [];
          if (response.data.cursor === '') {
            setIsLoading(false);
          }
          setProducts((p) => [...p, ...e]);
          productsPaginationCursor.current = response.data.cursor;
        })
        .catch(genericErrorFeedback('Error loading products'))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    httpGet('/products/categories')
      .then((response) => {
        const e = response.data || [];
        setCategories(e);
      })
      .catch(genericErrorFeedback('Error loading categories'))
      .finally(() => {});
  }, [setCategories]);

  useEffect(() => {
    httpGet('/products', {
      params: { cursor: productsPaginationCursor.current },
    })
      .then((response) => {
        const e = response.data.result || [];
        if (response.data.cursor === '') {
          setIsLoading(false);
        }
        setProducts((p) => [...p, ...e]);
        productsPaginationCursor.current = response.data.cursor;
      })
      .catch(genericErrorFeedback('Error loading products'))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Page isLoading={isLoading}>
      <div className="m-lg flex flex-1 flex-col gap-lg rounded-2xl border border-solid bg-white p-lg">
        <div className="flex justify-between gap-lg">
          <div>
            <ProductSearchBar
              onSubmit={onSearchClicked}
              categories={categories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          </div>

          <div className="flex gap-smd">
            <Button
              title="Export"
              onClick={onExportClicked}
              disabled={isLoading}
            />
            <Tooltip position="bottom" label="This feature is currently not available">
              <Button
                title="Import"
                onClick={onImportClick}
                disabled
              />
            </Tooltip>
          </div>
        </div>

        <ProductsTable
          products={products}
          openDialog={openDialog}
          isLoading={isLoading}
          onScrolledEnd={onScrolledEnd}
        />

        {showDialog && (
          <ProductEditDialog
            open={showDialog}
            setOpen={setShowDialog}
            products={products}
            setProducts={setProducts}
            idx={selectedIdx}
          />
        )}
      </div>
    </Page>
  );
};

export default ProductsListPage;
