import { LoadingOverlay } from '@mantine/core';
import { useFormContext } from '../../../../contexts/useFormContext';
import { Phone } from '../../../../models/Business';
import { FormInput } from '../../../FormInput';
import { FormInputPhones } from '../../../FormInputPhones';

const _Body = () => {
  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    isLoading,
  } = useFormContext<{
    name: string;
    externalId: string;
    address: string;
    email: string;
    phones: Phone[];
  }>();

  return (
    <div className="flex flex-col gap-5 px-1 py-3">
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ type: 'dots' }}
        overlayProps={{ blur: 2 }}
      />
      <FormInput
        required
        field="name"
        label="Name"
        value={values.name}
        error={errors.name}
        touched={touched.name}
        onChange={handleChange('name')}
        onBlur={handleBlur('name')}
      />

      <FormInput
        field="externalId"
        label="Customer ID"
        value={values.externalId}
        error={errors.externalId}
        touched={touched.externalId}
        onChange={handleChange('externalId')}
        onBlur={handleBlur('externalId')}
      />

      <FormInput
        field="address"
        label="Address"
        value={values.address}
        error={errors.address}
        touched={touched.address}
        onChange={handleChange('address')}
        onBlur={handleBlur('address')}
      />

      <FormInput
        field="email"
        label="Email"
        value={values.email}
        error={errors.email}
        touched={touched.email}
        onChange={handleChange('email')}
        onBlur={handleBlur('email')}
      />

      <FormInputPhones
        field="phones"
        label="Phones"
        values={values.phones}
        errors={errors.phones}
        touched={touched.phones}
        setFieldValue={setFieldValue}
        onBlur={handleBlur('phones')}
      />
    </div>
  );
};

export { _Body };
