import {
  useCallback,
  useEffect,
} from 'react';
import {
  Accordion,
} from '@mantine/core';
import LoadingOverlay from 'components/ui/LoadingOverlay';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { groupFields } from 'helpers/prompt';
import { useSchemasContext } from 'contexts/useSchemasContext';

import AssigneeAccordionItem from './accordion-items/AssigneeAccordionItem';
import GroupFieldAccordionItem from './accordion-items/GroupFieldAccordionItem';

const OrderDraftPromptRender = () => {
  const { schemas } = useSchemasContext();
  const {
    order,
    groupedFields, setGroupedFields,
    typeRef, setTypeRef,
    confirmedFields, onConfirmField,
    accordionValue, setAccordionValue,
    onAccordionItemClick,
    setFirstAccordionValue,
  } = useProcessOrderContext();

  const onMarkAsCheckedButtonClick = useCallback((key: string) => {
    onConfirmField(key);
  }, [onConfirmField]);

  useEffect(() => {
    const _typeRef = order?.typeSpecs?.[0]?.typeRef;

    setTypeRef(_typeRef);

    const _groupedFields = groupFields(order?.typeSpecs?.[0]?.fields, schemas[_typeRef]);

    setGroupedFields(_groupedFields);

    setFirstAccordionValue(_groupedFields);
  }, [schemas, order?.typeSpecs, setFirstAccordionValue, setGroupedFields, setTypeRef]);

  if (groupedFields.length === 0) {
    return <LoadingOverlay visible displayText="Loading order processing view" />;
  }

  return (
    <Accordion
      multiple
      transitionDuration={300}
      value={accordionValue}
    >
      <AssigneeAccordionItem
        setAccordionValue={setAccordionValue}
        accordionValue={accordionValue}
      />
      {
          groupedFields.map((groupedField) => (
            <GroupFieldAccordionItem
              key={groupedField.title}
              groupKey={groupedField.title}
              isConfirmed={confirmedFields.includes(groupedField.title)}
              showItem={accordionValue.includes(groupedField.title)}
              groupedFields={groupedField}
              selectedOrder={order}
              schemas={schemas}
              typeRef={typeRef}
              onAccordionItemClick={onAccordionItemClick}
              onMarkAsCheckedButtonClick={onMarkAsCheckedButtonClick}
            />
          ))
      }
    </Accordion>
  );
};

export default OrderDraftPromptRender;
