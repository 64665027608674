import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';

import { Carousel } from '@mantine/carousel';
import { twMerge } from 'tailwind-merge';
import { Card } from 'components/common/Card';
import { Button } from '../../ui/Button';
import { IntentProps } from './types';

import classes from './IntentCard.module.css';

interface Props {
  cardTitle: string;
  cardSubtitle: string;
  intentProps: IntentProps[];
  className?: string;
  soon?: boolean;
}

const Slide = ({
  messageIntent,
  soon,
}: {
  messageIntent: IntentProps;
  soon?: boolean;
}) => {
  if (soon) {
    return (
      <Carousel.Slide className="flex-grow flex flex-col h-full">
        <div className="w-full flex justify-center items-center flex-grow">
          <span className="text-xl font-bold text-gray-500">
            coming soon
          </span>
        </div>
      </Carousel.Slide>
    );
  }

  return (
    <Carousel.Slide className="relative">
      <div className="w-full py-4 flex justify-center items-center">
        {messageIntent.cardTitle}
      </div>
      <div className="flex gap-2">
        {messageIntent.buttons.map((button, index) => (
          <Button
            key={`${messageIntent.cardTitle}-button-${index.toString()}`}
            title={button.title}
            theme={button.theme}
            onClick={button.onClick}
          />
        ))}
      </div>
    </Carousel.Slide>
  );
};

const IntentCard = ({
  cardTitle, cardSubtitle, intentProps, className, soon,
}: Props) => {
  const autoplay = useRef(Autoplay({ delay: 4000 }));

  const indicatorsStyle = intentProps.length < 2
    ? { display: 'none' }
    : {
      bottom: '100%',
      justifyContent: 'end',
      insetInlineEnd: '35%',
      gap: '3px',
    };

  const controlsStyle = intentProps.length < 2 ? { display: 'none' } : {};

  return (
    <Card
      className={twMerge(
        'col-span-4 row-span-1',
        className,
      )}
    >
      <Card.Body>
        <h4 className="card-title flex flex-col items-start">
          <span className="text-sm font-semibold text-gray-800">
            {cardTitle}
          </span>
          <span className="mt-1 text-xs text-gray-500">{cardSubtitle}</span>
        </h4>
        <div className="w-full overflow-hidden">
          <Carousel
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            withIndicators
            classNames={classes}
            styles={{
              // indicator: {
              // backgroundColor: 'blue',
              // },
              indicators: indicatorsStyle,
              controls: controlsStyle,
            }}
          >
            {intentProps.map((messageIntent, i) => (
              <Slide
                key={`${messageIntent.cardTitle}-slide-${i.toString()}`}
                messageIntent={messageIntent}
                soon={soon}
              />
            ))}
          </Carousel>
        </div>
      </Card.Body>
    </Card>
  );
};

export default IntentCard;
