import { useCallback, useMemo } from 'react';
import Dayjs from 'dayjs';

import { FieldSpec, Schema } from 'models/Schema';

import {
  getFieldByPath, inputTypeToUniversalFieldType, getValueByPath,
} from 'helpers/schema';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';

import { UniversalField } from 'components/common/UniversalField';
import { StandardField } from 'components/common/StandardField';

interface Props {
  obj: any;
  schema: Schema;
  fieldSpec: FieldSpec;
  setError: (key: string, error: string) => void;
}

const GroupedFieldsFieldItem = ({
  obj, schema, fieldSpec, setError: _setError,
}: Props) => {
  const { updateValueByPath } = useProcessOrderContext();

  const field = getFieldByPath(schema?.fields, fieldSpec.path);

  const fieldValue = useMemo(() => (
    getValueByPath(obj, fieldSpec.modelAutoMatchedPath)) || getValueByPath(obj, fieldSpec.modelPath),
  [fieldSpec.modelPath, fieldSpec.modelAutoMatchedPath, obj]);

  const onDeliveryDateTimeChange = useCallback((datetime: Date) => updateValueByPath(
    datetime ? Dayjs(datetime).format() : null, fieldSpec.modelPath), [updateValueByPath, fieldSpec.modelPath]);

  const onValueChange = useCallback(
    (value: string | Date | number) => updateValueByPath(
      value, fieldSpec.modelPath), [updateValueByPath, fieldSpec.modelPath]);

  const setError = useCallback((error: string) => {
    _setError?.(fieldSpec.path, error);
  }, [_setError, fieldSpec.path]);

  const props = useMemo(() => ({
    key: fieldSpec.path,
    type: inputTypeToUniversalFieldType(field?.inputType, field?.type),
    label: fieldSpec.name,
    value: fieldValue,
    shouldScroll: false,
    requestedDeliveryTime: obj.requestedDeliveryTime,
    autoMatchedRequestedDeliveryTime: obj.autoMatchedRequestedDeliveryTime,
    onDeliveryDateTimeChange,
    onValueChange,
  }), [field?.inputType, field?.type, fieldSpec.name,
    fieldSpec.path, fieldValue, obj.requestedDeliveryTime, obj.autoMatchedRequestedDeliveryTime,
    onDeliveryDateTimeChange, onValueChange]);

  return (
    field?.isStandard
      ? (
        <StandardField
          fieldSchema={field}
          setError={_setError}
          props={props}
        />
      )
      : (
        <UniversalField
          type={inputTypeToUniversalFieldType(field?.inputType, field?.type)}
          label={fieldSpec.name}
          value={fieldValue}
          validation={fieldSpec.validation}
          shouldScroll={false}
          onValueChange={onValueChange}
          setError={setError}
        />
      )
  );
};

export default GroupedFieldsFieldItem;
