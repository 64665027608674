import {
  action, makeObservable, observable, runInAction,
} from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { debounce } from 'lodash';

import { httpGet } from 'helpers/xhr';
import { GetDocumentsQueryType } from '../../models/GetDocumentsQueryType';
import { globalSseSources } from '../globalSseSources';
import { MessageIntent, MessageChannel } from '../../models/Message';

export class MessageIntents {
  unreadCounts: Record<MessageIntent, number> = {
    [MessageIntent.ORDER]: 0,
    [MessageIntent.OTHER]: 0,
  };

  totalUnreadCount: number = 0;

  isLoading?: boolean = false;

  constructor() {
    makeObservable(this, {
      unreadCounts: observable,
      isLoading: observable,
      load: action,
      _onNewMessageSseReceived: action,
    });
  }

  initPersistence = async () => {
    await makePersistable(this, {
      name: 'unread_counts',
      properties: [],
      storage: window.localStorage,
    });
  };

  load = async () => {
    runInAction(() => {
      this.isLoading = true;
    });

    this.totalUnreadCount = await httpGet('/chat/messages', {
      params: {
        unread_only: true,
        query_type: GetDocumentsQueryType.count,
        sources: [MessageChannel.IMAP],
      },
    }).then((response) => response.data.count);

    const intents = Object.values(MessageIntent);
    const counts = await Promise.all(intents.map((intent) => httpGet('/chat/messages', {
      params: {
        intent,
        unread_only: true,
        query_type: GetDocumentsQueryType.count,
      },
    }).then((response) => ({ intent, count: response.data.count })),
    ));

    this.unreadCounts = counts.reduce((acc, { intent, count }) => {
      acc[intent] = count;
      return acc;
    }, {} as Record<MessageIntent, number>);

    runInAction(() => {
      this.isLoading = false;
    });
  };

  _debouncedLoad = debounce(this.load, 2000);

  addSseSourcesHandler = () => {
    globalSseSources.addSourcesHandler(
      'messages/new_message',
      ['new_message'],
      this._onNewMessageSseReceived,
    );
  };

  // eslint-disable-next-line class-methods-use-this
  removeSseSourcesHandler = () => {
    globalSseSources.removeSourcesHandler('messages/new_message');
  };

  _onNewMessageSseReceived = () => {
    this._debouncedLoad();
  };
}
