import {
  memo, useCallback, useMemo,
} from 'react';

import { UniversalField } from 'components/common/UniversalField';
import {
  getFieldByPath, inputTypeToUniversalFieldType,
} from 'helpers/schema';
import { StandardField } from 'components/common/StandardField';

import { ProductWithQuantity } from 'features/order/models/Order';
import { FieldSpec, Schema } from 'models/Schema';

interface Props {
  product: ProductWithQuantity;
  getProductFieldValueByPath: (path: string) => any;
  updateProductFieldValueByPath: (path: string, value: any) => void;

  fieldSpec: FieldSpec;
  schema: Schema;

  setError: (key: string, error: string) => void;
}

const Field = ({
  product,
  getProductFieldValueByPath,
  updateProductFieldValueByPath,
  fieldSpec,
  schema,
  setError: _setError,
}: Props) => {
  const field = useMemo(() => getFieldByPath(schema?.fields, fieldSpec.path), [schema?.fields, fieldSpec.path]);

  const value = useMemo(() => (
    getProductFieldValueByPath(fieldSpec.modelPath)), [fieldSpec.modelPath, getProductFieldValueByPath]);

  const itemStyle = useMemo(() => ({
    minWidth: `${fieldSpec.name?.length}ch`,
    maxWidth: '300px',
    flexGrow: 1,
  }), [fieldSpec.name]);

  const type = useMemo(() => inputTypeToUniversalFieldType(field?.inputType, field?.type), [field?.inputType, field?.type]);

  const onValueChange = useCallback((_value: any) => updateProductFieldValueByPath(
    fieldSpec.modelPath, _value), [updateProductFieldValueByPath, fieldSpec.modelPath]);

  const props = useMemo(() => ({
    productWithQuantity: product,
    onUnitChange: onValueChange,

    key: fieldSpec.path,
    type,
    label: fieldSpec.name,
    value,
    size: 'xs' as 'xs',
    shouldScroll: false,
    itemStyle,
    onValueChange,
  }), [fieldSpec.name, fieldSpec.path, onValueChange, product, type, value, itemStyle]);

  const setError = useCallback((error: string) => {
    _setError?.(`${product.uiId}-${fieldSpec.path}`, error);
  }, [fieldSpec.path, _setError, product.uiId]);

  const setStandardFieldError = useCallback((key: string, error: string) => {
    _setError?.(`${product.uiId}-${fieldSpec.path}-${key}`, error);
  }, [_setError, product.uiId, fieldSpec.path]);

  return (
    <div
      id={`content-${fieldSpec.uiId}`}
      className="flex py-2 px-1 items-end flex-1"
    >
      {field?.isStandard
        ? (
          <StandardField
            fieldSchema={getFieldByPath(schema?.fields, fieldSpec.path)}
            setError={setStandardFieldError}
            props={props}
          />
        )
        : (
          <UniversalField
            key={fieldSpec.path}
            type={type}
            label={fieldSpec.name}
            value={value}
            validation={fieldSpec.validation}
            size="xs"
            shouldScroll={false}
            itemStyle={itemStyle}
            onValueChange={onValueChange}
            setError={setError}
          />
        )}
    </div>
  );
};

export default memo(Field);
