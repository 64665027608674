import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { globalOrderDrafts } from 'state/globalOrderDrafts';
import { ROUTES } from 'config/routes';

import { Order } from '../models/Order';

interface OrderDraftNavigationProps {
  orderDrafts: Order[];
  setOrderDrafts: React.Dispatch<React.SetStateAction<Order[]>>;
  loadOrderDrafts: () => Promise<Order[]>;
  isOrderDraftsLoading: boolean;
}

const useOrderDraftNavigation = ({
  orderDrafts,
  setOrderDrafts,
  loadOrderDrafts,
  isOrderDraftsLoading,
}: OrderDraftNavigationProps) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(-1);

  const selectNextOrderDraft = useCallback(
    (increment: boolean = true) => {
      if (isOrderDraftsLoading) {
        return;
      }

      if (orderDrafts.length <= 0) {
        setCurrentIndex(-1);
        return;
      }

      if (currentIndex === null) {
        setCurrentIndex(0);
        return;
      }

      // Already processed all order drafts
      if (globalOrderDrafts.count === 0) {
        navigate(ROUTES.HOME);
        return;
      }

      // If it's the last order draft, next index is the current index
      let nextIndex: number;
      if (currentIndex === globalOrderDrafts.count) {
        nextIndex = currentIndex;
      } else {
        nextIndex = currentIndex + 1;
      }

      if (!increment) nextIndex -= 1; // in case the order was confirmed

      if (nextIndex < orderDrafts.length) {
        setCurrentIndex(nextIndex);
      } else if (nextIndex < globalOrderDrafts.count) {
        loadOrderDrafts()
          .then((results) => {
            if (results.length === 0) {
              setCurrentIndex(-1);
              navigate(ROUTES.HOME);
            } else {
              setCurrentIndex(nextIndex);
            }
          })
          .catch(() => {
            setCurrentIndex(-1);
          });
      }
    },
    [orderDrafts.length, currentIndex, loadOrderDrafts, navigate, isOrderDraftsLoading],
  );

  const selectPrevOrderDraft = useCallback(() => {
    if (orderDrafts.length > 0) {
      if (currentIndex !== null) {
        const nextIndex = currentIndex - 1;
        if (nextIndex >= 0) {
          setCurrentIndex(nextIndex);
        }
      } else {
        setCurrentIndex(0);
      }
    } else {
      setCurrentIndex(-1);
    }
  }, [orderDrafts.length, currentIndex]);

  const onOrderDraftProcessed = useCallback(
    (orderId: string) => {
      setOrderDrafts(
        (_orderDrafts) => _orderDrafts.filter((_orderDraft) => _orderDraft.id !== orderId),
      );
      globalOrderDrafts.processOrder();
      selectNextOrderDraft(false);
    },
    [selectNextOrderDraft, setOrderDrafts],
  );

  useEffect(() => {
    if (orderDrafts.length > 0 && currentIndex === -1) {
      setCurrentIndex(0);
    }
  }, [orderDrafts, currentIndex]);

  return {
    currentIndex,
    setCurrentIndex,
    selectNextOrderDraft,
    selectPrevOrderDraft,
    onOrderDraftProcessed,
  };
};

export { useOrderDraftNavigation };
