import {
  useEffect,
} from 'react';

import { Page } from 'components/layout/Page/Page';
import { useMessagesContext } from 'contexts/useMessagesContext';
import { useFetchCustomerById } from 'hooks/fetch/useFetchCustomerById';
import { isZeroTime } from 'helpers/dateTime';
import { useMarkMessageAsRead } from 'hooks/useMarkMessageAsRead';
import { ProcessMessage } from '../components/process-message';

const MessageByIdPage = () => {
  const { messages, isLoading: isMessagesLoading } = useMessagesContext();
  const {
    customer,
    loadCustomer,
    isLoading: isCustomerLoading,
  } = useFetchCustomerById();
  const { markMessagesAsRead } = useMarkMessageAsRead();

  useEffect(() => {
    if (messages.length > 0) {
      loadCustomer(messages[0].businessSentBy);

      if (isZeroTime(messages[0].readAt)) {
        markMessagesAsRead([messages[0].id]);
      }
    }
  }, [messages, loadCustomer, markMessagesAsRead]);

  return (
    <Page contentWithBorder>
      <ProcessMessage
        messages={messages}
        customer={customer}
        isMessagesLoading={isMessagesLoading}
        isCustomerLoading={isCustomerLoading}
      />
    </Page>
  );
};

export default MessageByIdPage;
