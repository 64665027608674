import {
  useCallback, useEffect, useState,
} from 'react';
import { LoadingOverlay, Textarea } from '@mantine/core';

import { SelectOption } from 'components/ui/Select';
import { Order } from 'features/order/models/Order';
import { Button } from 'components/ui/Button';
import { useTeamMemnersContext } from 'contexts/useTeamMembersContext';
import { AssigneeSelect } from './AssigneeSelect';

interface Props {
  order: Order;
  assignOrder: (
    teamMemberId: string,
    teamMemberName: string,
    assignedComment: string,
  ) => void;

  // Optional
  title?: string;
  description?: string;
  onCancel?: () => void;
  preventFetch?: boolean;

  assignMe?: boolean;
}

const defaultTitle = 'Order Assignment Required';
const defaultDescription = 'This order has not yet been assigned. Please assign it to yourself or another team member before proceeding. You may also add a comment if necessary.';

const Assignee = ({
  order,
  assignOrder,
  title = defaultTitle,
  description = defaultDescription,
  onCancel,
  preventFetch = false,
  assignMe = true,
}: Props) => {
  const {
    teamMembers, isLoading, loadTeamMembers,
  } = useTeamMemnersContext();

  const [assignedTeamMember, setAssignedTeamMember] = useState<SelectOption>(null);
  const [assignedComment, setAssignedComment] = useState<string>('');

  const onAssignButtonClick = useCallback(() => {
    if (assignedTeamMember?.value) {
      assignOrder(
        assignedTeamMember.value,
        assignedTeamMember.label,
        assignedComment,
      );
    }
  }, [
    assignedTeamMember?.value,
    assignedTeamMember?.label,
    assignOrder,
    assignedComment,
  ]);

  // Set comment if present
  useEffect(() => {
    if (preventFetch) return;

    if (order?.draft?.comment) {
      setAssignedComment(order.draft.comment);
    } else {
      setAssignedComment('');
    }
  }, [order, setAssignedComment, preventFetch]);

  if (isLoading) {
    return (
      <div className="relative flex h-full flex-1 items-center justify-center">
        <LoadingOverlay
          visible
          loaderProps={{ type: 'dots' }}
          overlayProps={{ blur: 2 }}
        />
      </div>
    );
  }

  return (
    <div>
      {/* title */}
      <div className="pb-xl">
        <h1 className="pb-sm text-title-sm font-semibold">
          {title}
        </h1>
        <div className="text-label-caption-md text-description">
          {description}
        </div>
      </div>
      {/* main */}
      <div className="space-y-4">
        <AssigneeSelect
          teamMembers={teamMembers}
          assignedTeamMember={assignedTeamMember}
          setAssignedTeamMember={setAssignedTeamMember}
          loadTeamMembers={loadTeamMembers}
          assignMe={assignMe}
        />

        <Textarea
          placeholder="Add some comment if needed..."
          label="Comment"
          autosize
          minRows={2}
          maxRows={4}
          value={assignedComment}
          onChange={(event) => setAssignedComment(event.currentTarget.value)}
        />

        <div
          className={`flex w-full ${onCancel ? 'justify-between' : 'justify-end'}`}
        >
          {onCancel && (
            <Button title="Cancel" theme="secondary" onClick={onCancel} />
          )}
          <Button
            title="Assign"
            theme="primary"
            disabled={assignedTeamMember === null}
            onClick={onAssignButtonClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Assignee;
