import { useState, useCallback } from 'react';

import { httpPatch } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { globalAlertData } from 'state/globalAlertData';

import { Order } from '../models/Order';

const useSaveOrderDrafts = () => {
  const [isLoading, setIsLoading] = useState(false);

  // const _saveOrderDraft = useCallback((
  //   orderDraft: Order,
  //   createAlertData: boolean,
  //   giveErrorFeedback: boolean,
  // ): Promise<void> => httpPatch(`/orders/drafts/${orderDraft.id}`, {
  //   customerId: orderDraft.customer.id,
  //   requestedDeliveryTime: orderDraft.requestedDeliveryTime,
  //   products: orderDraft.products,
  //   comment: orderDraft.draft.comment,
  // })
  //   .then(() => {
  //     if (createAlertData) {
  //       globalAlertData.create('order updated successfully');
  //     }

  //     return Promise.resolve();
  //   })
  //   .catch((error) => {
  //     if (giveErrorFeedback) {
  //       genericErrorFeedback('Save order failed')(error);
  //     }
  //     return Promise.reject(error);
  //   })
  //   .finally(() => setIsLoading(false)), []);

  const saveOrderDrafts = useCallback(
    async (
      orderDrafts: Order[],
      createAlertData: boolean = true,
      giveErrorFeedback: boolean = true,
    ): Promise<Order[]> => {
      if (isLoading) {
        return Promise.reject(
          new Error('Save order drafts is already in progress'),
        );
      }

      setIsLoading(true);

      try {
        const response = await httpPatch('/orders/drafts', {
          updates: orderDrafts.map((orderDraft) => ({
            number: orderDraft?.number,
            order_id: orderDraft?.id,
            customerId: orderDraft?.customer?.id,
            requestedDeliveryTime: orderDraft?.requestedDeliveryTime,
            order_date: orderDraft?.orderDate,
            products: orderDraft?.products,
            comment: orderDraft?.draft?.comment,
            freeFields: orderDraft?.freeFields,
          })),
        });

        if (createAlertData) {
          globalAlertData.create('Orders saved successfully');
        }

        return await Promise.resolve(response.data);
      } catch (error) {
        if (giveErrorFeedback) {
          genericErrorFeedback('Save order failed')(error);
        }
        return await Promise.reject(error);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading],
  );

  return { isLoading, saveOrderDrafts };
};

export { useSaveOrderDrafts };
