import { useEffect, useMemo, memo } from 'react';
import { ExclamationTriangleIcon, UserIcon } from '@heroicons/react/20/solid';
import Dayjs from 'dayjs';

import {
  differenceInDays,
  differenceInHours,
  differenceInSeconds,
  isSameDay,
} from 'date-fns';
import { OrderBody } from 'components/chat/Chat/ChatMessage/MessageBody/OrderBody';
import { RecordingMessage } from 'components/chat/Chat/ChatMessage/RecordingMessage';
import { Message, MessageContextUtils } from 'models/Message';
import { Business } from 'models/Business';
import { usePlayRecordingContext } from 'contexts/usePlayRecordingIndex';
import { useMessagesContext } from 'contexts/useMessagesContext';
import { ImageMessage } from 'components/chat/Chat/ChatMessage/ImageMessage';
import { AttachmentList } from 'components/chat/Chat/ChatMessage/AttachmentList/AttachmentList';
import { useMarkMessageAsRead } from 'hooks/useMarkMessageAsRead';
import { isZeroTime } from 'helpers/dateTime';
import { decodeEntities } from 'utils/messageUtils';

interface Props {
  messages: Message[];
  customer: Business;
  onManageCustomerButtonClick?: () => void;
}

const OrderMessages = memo(({
  messages,
  customer,

  onManageCustomerButtonClick,
}: Props) => {
  const { setSelectedMessageId } = useMessagesContext();
  const { setIsRecordingAvailable } = usePlayRecordingContext();
  const message = useMemo(() => messages[0], [messages]);

  const { markMessagesAsRead } = useMarkMessageAsRead();

  useEffect(() => {
    if (message && isZeroTime(message.readAt)) {
      markMessagesAsRead([message.id]);
    }
  }, [message, markMessagesAsRead]);

  const customerName = useMemo(() => {
    if (!customer) return '';

    if (!customer?.customer?.isConfirmed) return 'Unkown customer';

    return customer?.name;
  }, [customer]);

  const showRecording = useMemo(
    () => MessageContextUtils.audioAttachments(message?.context).length > 0,
    [message],
  );
  const showImage = useMemo(
    () => MessageContextUtils.documentAttachments(message?.context).length > 0,
    [message],
  );

  const showAttachments = useMemo(
    () => showRecording || showImage,
    [showRecording, showImage],
  );

  const messageDatetimeText: string = useMemo(() => {
    if (!message?.createdAt) return '';

    const messageCreatedAt = new Date(message?.createdAt);
    const now = new Date();
    const diffInSeconds = differenceInSeconds(now, messageCreatedAt);

    let additionalText = '';

    if (diffInSeconds < 10) {
      additionalText = '(Just now)';
    } else {
      const diffInHours = differenceInHours(now, messageCreatedAt);
      if (diffInHours < 25 && isSameDay(now, messageCreatedAt)) {
        additionalText = `${diffInHours} hours ago`;
      } else {
        const diffInDays = differenceInDays(now, messageCreatedAt);
        if (diffInDays < 8) {
          additionalText = `${diffInDays} days ago`;
        }
      }
    }

    return `${Dayjs(messageCreatedAt).format('llll')}${additionalText !== '' ? ` (${additionalText})` : ''}`;
  }, [message]);

  useEffect(() => {
    if (message) {
      setIsRecordingAvailable(MessageContextUtils.audioAttachments(message?.context).length > 0);
    }
  }, [setIsRecordingAvailable, message]);

  useEffect(() => {
    if (message) {
      setSelectedMessageId(message.id);
    }
  }, [setSelectedMessageId, message]);
  return (
    <div className="flex-1 overflow-y-auto w-full px-2xl py-xl">
      {/* Header part */}
      <div className="flex w-full items-end justify-between">
        {/* User info */}
        <div className="flex items-center space-x-2">
          {/* User icon */}
          <div className="flex items-center justify-center rounded-full bg-gray-300">
            <UserIcon className="h-[2.5rem] w-[2.5rem] text-white" />
            <ExclamationTriangleIcon className="hidden h-[2.5rem] w-[2.5rem] text-warning-button" />
          </div>
          {/* User details */}
          <div className="flex flex-col justify-center">
            <div className="text-content-md font-semibold">
              {customerName}
            </div>
            <div className="text-content-sm">
              {customer?.email
                || (customer?.emails || [])[0]
                || (customer?.phones || [])[0]?.number}
            </div>
          </div>
        </div>

        {/* Message datetime */}
        <div className="flex">
          <p className="text-xs text-neutral-200 underline">
            {messageDatetimeText}
          </p>
        </div>
      </div>

      {customer && customer?.customer && !customer?.customer?.isConfirmed && onManageCustomerButtonClick && (
        <div className="mt-lg flex h-10 w-full items-center justify-between border-l-2 border-warning-border bg-warning-bg px-lg text-warning-title">
          <div className="flex items-center gap-x-md">
            <ExclamationTriangleIcon className="aspect-square h-5 text-warning-button" />
            Unkown customer detected
          </div>
          <div>
            <button
              type="button"
              className="px-1 text-warning-description"
              onClick={onManageCustomerButtonClick}
            >
              Manage
            </button>
          </div>
        </div>
      )}

      {message?.context?.subject && (
        <div className="pt-4 text-title-md font-semibold lg:text-title-lg">
          {decodeEntities(message?.context?.subject)}
        </div>
      )}

      <div className="divide-y-4 pt-4 text-content-smd text-lg lg:text-content-md">
        {message && <OrderBody message={message} />}
      </div>

      {showAttachments && (
        <>
          <div className="mt-8 border-t border-blue-gray-100 pt-2 font-semibold">
            Attachments
          </div>
          {showRecording && (
            <div className="pt-3">
              {message && <RecordingMessage message={message} />}
            </div>
          )}

          {showImage && (
            <>
              <div className="pt-3 flex justify-center">
                <p className="text-[0.6rem] italic text-gray-400 text-center max-w-[400px]">
                  The document scanning feature is still in its alpha version. Please double check the
                  output and report issues to
                  {' '}
                  <a href="mailto:support@hoshii.ai">
                    support@hoshii.ai
                  </a>
                  .
                </p>
              </div>
              <div className="pt-3">
                {message && <ImageMessage message={message} />}
              </div>
            </>
          )}

          <div className="pt-3">
            <AttachmentList attachments={message?.context?.attachments || []} />
          </div>
        </>
      )}
    </div>
  );
});

export default OrderMessages;
