import { ContactUser } from './ContactUser';

import { OAuthBinding } from '../types/oauth';

enum BusinessType {
  Restaurant = 'RESTAURANT',
  Supplier = 'SUPPLIER',
}

class Business {
  id: string;

  externalId: string;

  name: string;

  nameSupplement?: string;

  shortName?: string;

  phones: Phone[];

  email: string;

  emails: string[];

  address: string;

  type: BusinessType;

  contactUsers: ContactUser[];

  createdAt: string;

  createdBy: string;

  external: boolean;

  updatedAt: string;

  customer?: BusinessCustomer;

  disabled?: boolean;

  oauthBindings: Map<string, OAuthBinding>;

  // TODO: proper constructor
  constructor({
    name,
  }: {
    name: string;
  }) {
    this.name = name;
  }
}

type BusinessCustomerNote = {
  note: string;
};

class BusinessCustomer {
  isAutoCreated: boolean;

  isConfirmed: boolean;

  notes: BusinessCustomerNote[];
}

enum PhoneType {
  Unknown = 'unknown',
  Landline = 'landline',
  Mobile = 'mobile',
}

class Phone {
  uiId: string;

  number: string;

  isDefault: boolean;

  type: PhoneType;
}

export {
  Business, Phone, BusinessType, PhoneType,
};
