import {
  array, boolean, object, string,
} from 'yup';

import { useState } from 'react';
import { phone } from 'helpers/validations';

import { httpPatch } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { _Header as CreateHeader } from './_Header';
import { _Body as CreateBody } from './_Body';
import { _Footer as CreateFooter } from './_Footer';
import { Business, PhoneType } from '../../../../models/Business';
import { SidebarBase } from '../../SidebarBase';
import { globalAlertData } from '../../../../state/globalAlertData';
import { FormProvider } from '../../../../contexts/useFormContext';

const customerFormValidationSchema = object().shape({
  name: string().required(),
  email: string().email('Invalid email address'),
  address: string(),
  externalId: string(),
  phones: array(
    object({
      number: phone(),
      isDefault: boolean(),
      type: string().oneOf([PhoneType.Landline, PhoneType.Mobile]),
    }),
  ),
});

interface Props {
  customer: Business;
  _onComplete: (customer: Business) => void;
  onBack: () => void;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
}

const _Sidebar = ({
  customer,
  _onComplete,
  onBack,
  sidebarOpen,
  setSidebarOpen,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  if (!customer) {
    return null;
  }

  return (
    <FormProvider
      initialValues={{
        ...customer,
        name: '',
        email: customer.emails[0] || '',
        address: '',
        externalId: '',
        phones: [],
      }}
      validationSchema={customerFormValidationSchema}
      onSubmit={(values) => {
        setIsLoading(true);
        httpPatch(`/businesses/me/customers/${values.id}`, values)
          .then(() => {
            globalAlertData.create('Customer saved successfully');
            _onComplete(values);
          })
          .catch(genericErrorFeedback('Error saving customer'))
          .finally(() => {
            setIsLoading(false);
          });
      }}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    >
      <SidebarBase
        header={<CreateHeader onBack={onBack} />}
        body={<CreateBody />}
        footer={<CreateFooter isLoading={isLoading} />}
        sidebarWidth="max-w-[30vw]"
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    </FormProvider>
  );
};

export { _Sidebar };
