import {
  useCallback,
} from 'react';
import {
  Accordion,
} from '@mantine/core';

import { ProductSelect } from 'features/order/components/process-order-draft/form-elemts/ProductSelect';

import { ProductWithQuantity } from 'features/order/models/Order';
import { FieldSpec, Schema } from 'models/Schema';
import { Product } from 'models/Product';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { ProductSortingColumn } from 'types/product';
import Field from './CommonField';
import CommentField from './CommentField';
import Buttons from './ProductFIeldPanelButtons';

interface Props {
  product: ProductWithQuantity;
  fieldSpecs: FieldSpec[];
  schema: Schema;
  sortingColumn: ProductSortingColumn;
  filterEnabledProducts: boolean;
  customerId: string;
  updateProduct: (values: any) => void;
  setError: (key: string, error: string) => void;
  getProductFieldValueByPath: (path: string) => any;
  updateProductFieldValueByPath: (path: string, value: any) => void;
  onMarkAsCheckedButtonClick: () => void;
}

const Panel = ({
  product, fieldSpecs, schema, sortingColumn, filterEnabledProducts, customerId,
  setError, updateProduct,
  getProductFieldValueByPath, updateProductFieldValueByPath,
  onMarkAsCheckedButtonClick,
}: Props) => {
  const {
    removeProductByUiIdorPositionId, deleteErrorByPrefix,
  } = useProcessOrderContext();

  const onProductChange = useCallback((p: Product) => updateProduct({
    updatedName: p.name,
    id: p.id,
    score: p.score,
    product: p,
  }), [updateProduct]);

  const onRemoveProductButtonClick = useCallback(() => {
    removeProductByUiIdorPositionId(product?.positionId || product?.uiId);
    if (product?.uiId) {
      // Ensure to delete all errors for the product, in the future we can remove this line
      deleteErrorByPrefix(`${product.uiId}-`);
    }
  }, [product?.positionId, product?.uiId, removeProductByUiIdorPositionId, deleteErrorByPrefix]);

  const onTextAreaChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => updateProduct({
    comment: event.target.value,
  }), [updateProduct]);

  return (
    <Accordion.Panel
      style={{ backgroundColor: 'white' }}
    >
      <div
        className="flex flex-col gap-sm items-stretch w-full"
      >
        <div key={`preview-${product.uiId}`} className="flex flex-wrap gap-y-sm w-full">
          <ProductSelect
            label="Product"
            businessId={customerId}
            productWithQuantity={product}
            sortingColumn={sortingColumn}
            filterEnabledProducts={filterEnabledProducts}
            onProductChange={onProductChange}
          />
          {fieldSpecs.filter((fieldSpec) => fieldSpec.modelPath !== 'products.*.product.name').map((fieldSpec) => (
            <Field
              key={`preview-${fieldSpec.uiId}`}
              fieldSpec={fieldSpec}
              schema={schema}
              setError={setError}
              product={product}
              getProductFieldValueByPath={getProductFieldValueByPath}
              updateProductFieldValueByPath={updateProductFieldValueByPath}
            />
          ))}
        </div>
      </div>
      <CommentField
        defaultComment={product.comment}
        onTextAreaChange={onTextAreaChange}
      />
      <Buttons
        onRemoveProductButtonClick={onRemoveProductButtonClick}
        onMarkAsCheckedButtonClick={onMarkAsCheckedButtonClick}
        errors={product.errors}
        isProductConfirmed={product.confirmed}
      />
    </Accordion.Panel>
  );
};

export default Panel;
