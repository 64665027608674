import {
  useCallback, useState,
} from 'react';
import { LoadingOverlay, ScrollArea } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { Message } from 'models/Message';
import { useMessagesContext } from 'contexts/useMessagesContext';
import { useMarkMessageAsRead } from 'hooks/useMarkMessageAsRead';
import { ROUTES } from 'config/routes';

import MessageTableRow from './MessageTableRow';
import MessageTableHeader from './MessageTableHeader';

const MessageListTable = () => {
  const navigate = useNavigate();

  const {
    messages,
    setMessages,
    isLoading: isMessagesLoading,
    messagesFilter,
    setMessagesFilter,
    loadMessages,
  } = useMessagesContext();

  const {
    markMessagesAsRead: _markMessageAsRead,
    isLoading: isMarkMessageAsReadLoading,
  } = useMarkMessageAsRead();

  const [allSelected, setAllSelected] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState<Message[]>([]);

  const toggleAll = useCallback(() => {
    setSelectedMessages((prev) => (prev.length > 0 ? [] : messages));
    setAllSelected((prev) => !prev);
  }, [messages]);

  const reload = useCallback(() => {
    loadMessages(true);
  }, [loadMessages]);

  const onMessageRowClick = useCallback(
    (message: Message) => {
      navigate(ROUTES.MESSAGE_BY_ID(message.id));
    },
    [navigate],
  );

  const markMessagesAsRead = useCallback(() => {
    _markMessageAsRead(selectedMessages.map((m) => m.id)).then(() => {
      if (messagesFilter.unreadOnly) {
        setMessages((prev) => prev.filter((m) => !selectedMessages.includes(m)),
        );
      } else {
        setMessages((prev) => prev.map((m) => ({
          ...m,
          readAt:
              m.id === selectedMessages[0].id
                ? new Date().toISOString()
                : m.readAt,
        })),
        );
      }
      setSelectedMessages([]);
    });
  }, [
    _markMessageAsRead,
    messagesFilter.unreadOnly,
    selectedMessages,
    setMessages,
  ]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden rounded-md border border-solid">
      <ScrollArea.Autosize className="relative" onBottomReached={loadMessages}>
        {isMarkMessageAsReadLoading && (
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white/50">
            <LoadingOverlay
              visible={isMarkMessageAsReadLoading}
              loaderProps={{ type: 'dots' }}
              overlayProps={{ blur: 2 }}
            />
          </div>
        )}
        <MessageTableHeader
          allSelected={allSelected}
          toggleAll={toggleAll}
          reload={reload}
          markMessagesAsRead={markMessagesAsRead}
          selectedMessages={selectedMessages}
          messagesFilter={messagesFilter}
          setMessagesFilter={setMessagesFilter}
        />
        <table className="min-w-full divide-y divide-gray-300">
          <tbody className="divide-y divide-gray-200 bg-white">
            {messages.map((message) => (
              <MessageTableRow
                key={message.id}
                message={message}
                selectedMessages={selectedMessages}
                setSelectedMessages={setSelectedMessages}
                onMessageRowClick={onMessageRowClick}
              />
            ))}
          </tbody>
        </table>
        <div className="relative flex h-10 w-full items-center justify-center">
          <LoadingOverlay
            visible={isMessagesLoading}
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
        </div>
      </ScrollArea.Autosize>
    </div>
  );
};

export default MessageListTable;
