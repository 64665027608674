import {
  createContext,
  useContext,
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { MessageDirection } from '../types/message';
import { convertKeysToCamelCase } from '../helpers/mapping';
import {
  Message,
  MessageChannel,
  MessageStatus,
  MessageType,
} from '../models/Message';
import { Chat } from '../models/Chat';
import { getMessageDirection } from '../utils/messageUtils';
import { openAuthWebSocket } from '../helpers/ws';
import { genericErrorFeedback } from '../helpers/errors';
import { isAiChat as isAiChatHelper } from '../helpers/chat';
import { globalUser } from '../state/globalUser';
import { useSendLLMMessage } from '../hooks/useSendLLMMessage';

type ChatMessageContextType = {
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;

  isSendingLLMMessage: boolean;
  isAdamThinking: boolean;
  isAiChat: boolean;

  userInput: string;
  setUserInput: React.Dispatch<React.SetStateAction<string>>;

  onMessageSubmit: (pipeline?: string) => void;
};

const ChatMessagesContext = createContext<
ChatMessageContextType | undefined
>(undefined);

interface ChatMessagesProviderProps {
  children: React.ReactNode;

  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  chat: Chat;
  isAdamTemporaryChat?: boolean;
  isNewAdamChat?: boolean;
  chatGroupId?: string;
}

const ChatMessagesProvider: React.FC<ChatMessagesProviderProps> = ({
  children,
  setMessages,
  chat,
  isAdamTemporaryChat,
  isNewAdamChat,
  chatGroupId,
}: ChatMessagesProviderProps) => {
  const { sendLLMMessage, isLoading: isSendingLLMMessage } = useSendLLMMessage();

  const [isAdamThinking, setIsAdamThinking] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<string>('');

  const isAiChat = useMemo(() => isAiChatHelper(chat), [chat]);

  const messagesWs = useRef<WebSocket | null>();

  const onMessageSubmit = useCallback((pipeline?: string) => {
    // Currently we only support LLM messages
    if (!isAiChat && !isAdamTemporaryChat) return;

    const id = uuidv4();

    const temporalMessage = new Message(
      id,
      chat?.id,
      chatGroupId,
      globalUser.business.id,
      chat?.user?.id,
      globalUser.id,
      chat?.user?.id,
      userInput,
      MessageType.TEXT,
      MessageChannel.INTERNAL,
      '',
      null,
      {},
      '',
      new Date().toISOString(),
      null,
      null,
      '',
      MessageStatus.SENDING,
      false,
      true,
    );

    setMessages((prevMessages) => [
      ...prevMessages,
      temporalMessage,
    ]);

    setUserInput('');

    if (isAiChat || isAdamTemporaryChat) {
      setIsAdamThinking(true);
    }

    setTimeout(() => {
      const messageElement = document.getElementById(id);
      if (messageElement) {
        messageElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);

    let chatId = chat?.id;

    if (isNewAdamChat) {
      chatId = null;
    }

    sendLLMMessage(userInput, chatId, isAdamTemporaryChat, pipeline)
      .then((message) => {
        setMessages((prevMessages) => prevMessages.map((_message) => {
          if (_message.id === id) {
            return {
              ...message,
              messageStatus: MessageStatus.SENT,
            };
          }

          return _message;
        }));
      });
  }, [isAiChat, chat?.id, chat?.user?.id, userInput, setMessages, sendLLMMessage,
    isAdamTemporaryChat, chatGroupId, isNewAdamChat]);

  const onWsMessageReceived = useCallback((event: MessageEvent) => {
    const message = convertKeysToCamelCase(JSON.parse(event.data)) as Message;

    if (message.chatId !== chat?.id || (message.chatGroupId && message.chatGroupId !== chatGroupId)) return;

    const direction = getMessageDirection(message, chat?.business);

    if (direction === MessageDirection.Received) {
      setMessages((m) => [...m, { ...message, isAdded: true }]);

      if ((isAiChat || isAdamTemporaryChat) && isAdamThinking) {
        setIsAdamThinking(false);
      }
    }
  }, [chat?.id, chat?.business, setMessages, isAiChat, isAdamThinking, chatGroupId, isAdamTemporaryChat]);

  const onWsError = useCallback((error: unknown) => {
    genericErrorFeedback('Error loading live messages')(error);
  }, []);

  useEffect(() => {
    setUserInput('');
  }, [chat?.id]);

  useEffect(() => {
    messagesWs.current = openAuthWebSocket('/v1/chat/ws');
    messagesWs.current.addEventListener('message', onWsMessageReceived);
    messagesWs.current.addEventListener('error', onWsError);

    return () => {
      messagesWs.current?.removeEventListener('message', onWsMessageReceived);
      messagesWs.current?.removeEventListener('error', onWsError);
      messagesWs.current?.close();
      messagesWs.current = null;
    };
  }, [onWsMessageReceived, onWsError, chat]);

  const contextValue = useMemo(() => ({
    isSendingLLMMessage,
    isAdamThinking,
    isAiChat,
    userInput,
    setUserInput,
    onMessageSubmit,
    setMessages,
  }), [
    isSendingLLMMessage,
    isAdamThinking,
    userInput,
    setUserInput,
    onMessageSubmit,
    isAiChat,
    setMessages,
  ]);

  return (
    <ChatMessagesContext.Provider value={contextValue}>
      {children}
    </ChatMessagesContext.Provider>
  );
};

const useChatMessagesContext = () => {
  const context = useContext(ChatMessagesContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by BusinessSettingsContext provider.');
  }

  return context;
};

export {
  ChatMessagesProvider,
  useChatMessagesContext,
};
