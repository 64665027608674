import { ReactNode } from 'react';
import { Accordion, Tooltip } from '@mantine/core';

import { CheckIcon, ExclamationTriangleIcon, PlayIcon } from '@heroicons/react/24/outline';
import { usePlayRecordingContext } from 'contexts/usePlayRecordingIndex';
import { useMessagesContext } from 'contexts/useMessagesContext';
import { getTooltipLabel } from './utils';

interface Props {
  errors: Record<string, string>;
  isProductConfirmed: boolean;
  productName: string;
  positionId: string;
}

const getIcon = (tooltipLabel: string | ReactNode, isConfirmed: boolean) => {
  if (tooltipLabel) {
    return <ExclamationTriangleIcon className="w-5 h-5 text-warning-500" />;
  }

  if (isConfirmed) {
    return <CheckIcon className="w-5 h-5 text-success-700" />;
  }

  return <span />;
};

const Control = ({
  isProductConfirmed,
  productName,
  errors,
  positionId,
}: Props) => {
  const { isRecordingAvailable, setPlayRecordingKeywordAndMessageId } = usePlayRecordingContext();
  const { selectedMessageId } = useMessagesContext();

  const tooltipLabel = getTooltipLabel(errors);

  const onPlayButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setPlayRecordingKeywordAndMessageId({
      keyword: positionId,
      messageId: selectedMessageId,
    });
  };

  return (
    <Accordion.Control
      icon={(
        <Tooltip label={tooltipLabel} disabled={!tooltipLabel}>
          {getIcon(tooltipLabel, isProductConfirmed)}
        </Tooltip>
        )}
      style={{ backgroundColor: 'white' }}
      className="hover:bg-green-500"
    >
      <div className="flex space-x-2">
        <p className={isProductConfirmed ? 'text-success' : ''}>
          {productName}
        </p>
        {isRecordingAvailable && (
        <Tooltip
          label="Play audio"
          transitionProps={{ transition: 'pop', duration: 200 }}
          bg="white"
          styles={{
            tooltip: {
              color: 'black',
              border: '1.5px solid #E8E8E8',
              boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
            },
          }}
        >
          <button
            type="button"
            className="rounded-full border border-light-gray-100 bg-white p-1"
            onClick={onPlayButtonClick}
          >
            <PlayIcon className="aspect-square w-4" />
          </button>
        </Tooltip>
        )}
      </div>
    </Accordion.Control>
  );
};

export default Control;
