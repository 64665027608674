import { useCallback } from 'react';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { FieldSpec, Schema } from 'models/Schema';

import { CustomerSelect } from 'features/customer/components/CustomerSelect';
import CustomerField from './CustomerField';

interface Props {
  fieldSpecs: FieldSpec[];
  setError?: (key: string, error: string) => void;
  obj: any;
  schema: Schema;
}

const CustomerFields = ({
  fieldSpecs, setError, obj, schema,
}: Props) => {
  const { updateValueByPath } = useProcessOrderContext();

  const setCustomerSelectError = useCallback((error: string) => {
    setError?.('customer', error);
  }, [setError]);

  return (
    <div className="space-y-4 relative">
      <CustomerSelect setError={setCustomerSelectError} />
      {fieldSpecs.map((fieldSpec) => (
        <CustomerField
          key={fieldSpec.path}
          fieldSpec={fieldSpec}
          schema={schema}
          obj={obj}
          setError={setError}
          updateValueByPath={updateValueByPath}
        />
      ))}
    </div>
  );
};

export default CustomerFields;
