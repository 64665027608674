import {
  useCallback, useEffect, useRef, useMemo,
} from 'react';
import { observer } from 'mobx-react-lite';

import LoadingOverlay from 'components/ui/LoadingOverlay';
import { useOrderContext } from 'features/order/contexts/useOrderContext';
import { useMessagesContext } from 'contexts/useMessagesContext';
import { ManageCustomerSidebarWrapper } from 'components/wrapper/ManageCustomerSidebarWrapper';
import { Business } from 'models/Business';
import { useFetchOrderDrafts } from 'features/order/api/useFetchOrderDrafts';
import { isZeroId } from 'helpers/objectId';
import { getAssignedStatus } from 'features/order/utils/order';
import { AssignedStatus } from 'types/order';
import { WorkflowRun } from 'models/Workflow';
import { WorkflowRetrySidebarWrapper } from 'components/wrapper/WorkflowRetrySidebarWrapper';
import { useMarkMessageAsRead } from 'hooks/useMarkMessageAsRead';
import { isZeroTime } from 'helpers/dateTime';
import { useRetryWorkflow } from 'hooks/useRetryWorkflow';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { Order, OrderStatus } from 'features/order/models/Order';
import { MessageContextUtils } from 'models/Message';
import { OrderMessages } from './OrderMessages';
import { OrderDraftPanel } from './OrderDraftPanel';

interface Props {
  order: Order;
  isOrderLoading: boolean;

  onOrderRetried?: (orderId: string) => Promise<any>;
  assignGlobalCurrentOrder?: (assigneeId: string) => void;
}

const ProcessOrderDrafts = observer(({
  order,
  isOrderLoading,
  onOrderRetried,
  assignGlobalCurrentOrder,
}: Props) => {
  const { retryWorkflow, pendingLROs } = useRetryWorkflow({ onOrderWorkflowRunRetried: onOrderRetried });
  const { setOrder } = useProcessOrderContext();
  const {
    groupOrders, setGroupOrders, setSelectedIndex,
  } = useOrderContext();

  const { markMessagesAsRead } = useMarkMessageAsRead();

  const pendingOrderRetries = useMemo(() => new Set(
    Array.from(pendingLROs.values()).map((lro) => lro.data as string),
  ), [pendingLROs]);
  const isOrderRetrying = useMemo(() => pendingOrderRetries.has(order?.id), [pendingOrderRetries, order?.id]);

  const onRetryButtonClick = useCallback((
    selectedWorkflow: string, workflowRun: WorkflowRun, transcriptionLanguage?: string,
  ) => {
    retryWorkflow(selectedWorkflow, workflowRun, order?.id, transcriptionLanguage);
  }, [retryWorkflow, order?.id]);

  const { isLoading: isGroupLoading, loadOrderDrafts } = useFetchOrderDrafts({
    preventInitialFetch: true,
  });

  const { messages, isLoading: isMessageLoading } = useMessagesContext();

  const isVoiceAttachment = useMemo(() => (
    // messages.some((m) => MessageContextUtils.audioAttachments(m.context).length > 0)
    MessageContextUtils.audioAttachments(messages[0]?.context).length > 0
  ), [messages]);

  const transcriptionLanguage = useMemo(() => (
    messages[0]?.context?.workflowOrder?.audioLanguage
  ), [messages]);

  const manageCustomerModalWrapperRef = useRef(null);
  const workflowRetrySidebarWrapperRef = useRef(null);

  const onManageCustomerButtonClick = useCallback(() => {
    if (manageCustomerModalWrapperRef.current) {
      manageCustomerModalWrapperRef.current.onManageCustomerButtonClick();
    }
  }, []);

  const onOpenRetryWorkflowSidebarButtonClick = useCallback(() => {
    if (workflowRetrySidebarWrapperRef.current) {
      workflowRetrySidebarWrapperRef.current.onRetryWorkflowButtonClick();
    }
  }, []);

  const onCustomerReconciliationDone = useCallback(
    (customer: Business) => {
      setOrder((order_) => ({
        ...order_,
        customer,
      }));
    },
    [setOrder],
  );

  useEffect(() => {
    if (order && setGroupOrders && setSelectedIndex && loadOrderDrafts) {
      const assignedStatus = getAssignedStatus(order.assigneeId);
      if (assignedStatus === AssignedStatus.ME && order.groupId && !isZeroId(order.groupId)) {
        // Get order groups
        loadOrderDrafts(false, true, order.groupId).then((orders) => {
          setGroupOrders([order, ...(orders || [])]);
        });
      } else {
        setGroupOrders([{
          ...order,
          products: (order.products || []).map((product) => ({ ...product })),
        }]);
      }

      setSelectedIndex(0);
    }
  }, [loadOrderDrafts, order, setGroupOrders, setSelectedIndex]);

  useEffect(() => {
    if (messages.length > 0) {
      const toMark = messages.filter((m) => isZeroTime(m.readAt)).map((m) => m.id);
      if (toMark.length > 0) {
        markMessagesAsRead(toMark);
      }
    }
  }, [messages, markMessagesAsRead]);

  if (isOrderRetrying) {
    return (
      <div className="relative h-full">
        <LoadingOverlay visible displayText="Retrying order" />
      </div>
    );
  }

  if (order && order.status !== OrderStatus.New) {
    return (
      <div className="h-full flex justify-center items-center">
        This order has already been processed.
      </div>
    );
  }

  return (
    <div className="relative h-full overflow-hidden">
      <ManageCustomerSidebarWrapper
        ref={manageCustomerModalWrapperRef}
        customer={order?.customer}
        onComplete={onCustomerReconciliationDone}
      >
        <WorkflowRetrySidebarWrapper
          ref={workflowRetrySidebarWrapperRef}
          workflowRunId={order?.createdByWorkflowRunId}
          isVoiceAttachment={isVoiceAttachment}
          transcriptionLanguage={transcriptionLanguage}
          onRetryWorkflow={onRetryButtonClick}
        >
          <div className="flex h-full flex-1 overflow-hidden">
            <div className="max-[50%] relative h-full min-w-[50%] flex flex-col overflow-hidden border-r border-blue-gray-50">
              {!isZeroId(order?.createdByWorkflowRunId) && (
              <div className="relative w-full bg-white border-b flex py-1 px-lg text-sm">
                <p className="text-blue-gray-200 px-lg">
                  Was the order not processed properly or it is missing some information?
                  <button
                    type="button"
                    className="text-primary-500 pl-sm"
                    onClick={onOpenRetryWorkflowSidebarButtonClick}
                  >
                    Retry
                  </button>
                </p>
              </div>
              )}

              {
                isMessageLoading ? (
                  <LoadingOverlay
                    visible
                    displayText="Loading messages"
                  />
                ) : (
                  <OrderMessages
                    messages={messages}
                    customer={groupOrders[0]?.customer}
                    onManageCustomerButtonClick={onManageCustomerButtonClick}
                  />
                )
              }
            </div>
            <div className="max-[50%] relative h-full min-w-[50%]">
              {
                isOrderLoading || isGroupLoading ? (
                  <LoadingOverlay
                    visible={isOrderLoading || isGroupLoading}
                    displayText="Loading order details"
                  />
                ) : (
                  <OrderDraftPanel
                    isOrderProcessingMode
                    assignGlobalCurrentOrder={assignGlobalCurrentOrder}
                  />
                )
              }
            </div>
          </div>
        </WorkflowRetrySidebarWrapper>
      </ManageCustomerSidebarWrapper>
    </div>
  );
});

export default ProcessOrderDrafts;
