import {
  memo, ReactNode, useCallback, useMemo, useState,
} from 'react';
import { Accordion, Textarea, Tooltip } from '@mantine/core';
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';

import { GroupedFields } from 'types/schema';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { Button } from 'components/ui/Button';
import { Schema } from 'models/Schema';
import { Order } from 'features/order/models/Order';

import { GroupedFields as GroupedFieldsComponent } from '../fields';
import { getTooltipLabel } from '../fields/ProductFields/ProductField/utils';

interface Props {
  groupKey: string;
  isConfirmed: boolean;
  showItem: boolean;
  groupedFields: GroupedFields;
  selectedOrder: Order;
  schemas: Record<string, Schema>;
  typeRef: string;
  onAccordionItemClick: (key: string) => void;
  onMarkAsCheckedButtonClick: (key: string) => void;
}

const ButtonSection = memo(
  ({
    tooltipLabel,
    groupKey,
    isGroupKeyIncluded,
    onMarkAsCheckedButtonClick,
  }: {
    tooltipLabel: ReactNode | string;
    groupKey: string;
    isGroupKeyIncluded: boolean;
    onMarkAsCheckedButtonClick: (key: string) => void;
  }) => (
    <div className="flex justify-end pt-4">
      <Tooltip label={tooltipLabel} disabled={!tooltipLabel}>
        <Button
          title={`Mark as ${isGroupKeyIncluded ? 'unchecked' : 'checked'}`}
          onClick={() => onMarkAsCheckedButtonClick(groupKey)}
          disabled={!!tooltipLabel}
        />
      </Tooltip>
    </div>
  ),
);

const getIcon = (tooltipLabel: string | ReactNode, isConfirmed: boolean) => {
  if (tooltipLabel) {
    return <ExclamationTriangleIcon className="w-5 h-5 text-warning-500" />;
  }

  if (isConfirmed) {
    return <CheckIcon className="w-5 h-5 text-success-700" />;
  }

  return <span />;
};

const GroupFieldAccordionItem = ({
  groupKey,
  isConfirmed,
  groupedFields,
  selectedOrder,
  schemas,
  typeRef,
  showItem,
  onAccordionItemClick,
  onMarkAsCheckedButtonClick,
}: Props) => {
  const {
    onCommentChange,
    errors: errors_,
    setError: setError_,
  } = useProcessOrderContext();

  const [confirmedFields, setConfirmedFields] = useState<string[]>([]);
  const [subAccordionValue, setSubAccordionValue] = useState<string[]>([]);

  const errorValues: string[] = useMemo(
    () => Object.values(errors_[groupKey] || {}).filter((value) => value) || [],
    [errors_, groupKey],
  );

  const tooltipLabel = getTooltipLabel(errorValues);

  const CommentField = useMemo(
    () => (groupKey === 'Standard fields' ? (
      <Textarea
        placeholder="Add some comment if needed..."
        label="Comment"
        minRows={2}
        maxRows={4}
        autosize
        autoComplete="off"
        className="pt-4"
        value={selectedOrder?.draft?.comment || ''}
        onChange={onCommentChange}
      />
    ) : null),
    [groupKey, onCommentChange, selectedOrder?.draft?.comment],
  );

  const setError = useCallback(
    (key: string, error: string) => {
      setError_(groupKey, key, error);
    },
    [groupKey, setError_],
  );

  const onSubGroupMarkAsCheckedButtonClick = useCallback(
    (key: string) => {
      setConfirmedFields((prevCFs) => {
        if (prevCFs.includes(key)) {
          return prevCFs.filter((item) => item !== key);
        }

        setSubAccordionValue((prevAV) => {
          let newAV = prevAV.filter((item) => item !== key);

          const keyIdx = groupedFields.subGroups.findIndex(
            (group) => group.title === key,
          );
          if (
            keyIdx + 1 < groupedFields.subGroups.length
            && !prevCFs.includes(groupedFields.subGroups[keyIdx + 1].title)
          ) {
            newAV = [...newAV, groupedFields.subGroups[keyIdx + 1].title];
          }

          return newAV;
        });

        return [...prevCFs, key];
      });
    },
    [groupedFields, setSubAccordionValue],
  );

  const onSubGroupAccordionItemClick = useCallback(
    (key: string) => {
      setSubAccordionValue((prev) => {
        if (prev.includes(key)) {
          return prev.filter((item) => item !== key);
        }

        return [...prev, key];
      });
    },
    [setSubAccordionValue],
  );

  const onAccordionItemClickHandler = useCallback(() => {
    onAccordionItemClick(groupKey);
  }, [onAccordionItemClick, groupKey]);

  return (
    <Accordion.Item value={groupKey}>
      <Accordion.Control
        icon={(
          <Tooltip label={tooltipLabel} disabled={!tooltipLabel}>
            {getIcon(tooltipLabel, isConfirmed)}
          </Tooltip>
        )}
        onClick={onAccordionItemClickHandler}
      >
        <p
          className={`${isConfirmed ? 'text-success-700' : ''} -semibold text-lg`}
        >
          {groupKey}
        </p>
      </Accordion.Control>
      <Accordion.Panel>
        {showItem && (
          <>
            <GroupedFieldsComponent
              fieldSpecs={groupedFields.fields}
              obj={selectedOrder}
              schema={schemas[typeRef]}
              setError={setError}
            />

            {CommentField}

            {groupedFields.subGroups.length > 0 && (
              <Accordion
                multiple
                transitionDuration={300}
                value={subAccordionValue}
              >
                {groupedFields.subGroups.map((subGroup) => (
                  <GroupFieldAccordionItem
                    key={subGroup.title}
                    groupKey={subGroup.title}
                    isConfirmed={confirmedFields.includes(subGroup.title)}
                    showItem={subAccordionValue.includes(subGroup.title)}
                    groupedFields={subGroup}
                    selectedOrder={selectedOrder}
                    schemas={schemas}
                    typeRef={typeRef}
                    onAccordionItemClick={onSubGroupAccordionItemClick}
                    onMarkAsCheckedButtonClick={
                      onSubGroupMarkAsCheckedButtonClick
                    }
                  />
                ))}
              </Accordion>
            )}

            <ButtonSection
              tooltipLabel={tooltipLabel}
              groupKey={groupKey}
              isGroupKeyIncluded={isConfirmed}
              onMarkAsCheckedButtonClick={onMarkAsCheckedButtonClick}
            />
          </>
        )}
      </Accordion.Panel>
    </Accordion.Item>
  );
};

export default GroupFieldAccordionItem;
