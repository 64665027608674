import { Accordion, LoadingOverlay } from '@mantine/core';
import { memo, useCallback } from 'react';
import { Assignee } from 'features/order/components/process-order-draft/Assignee';
import { useAssignDraft } from 'features/order/api/useAssignDraft';
import { useOrderContext } from 'features/order/contexts/useOrderContext';
import { isZeroId } from 'helpers/objectId';
import { useFetchOrderDrafts } from 'features/order/api/useFetchOrderDrafts';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';

interface Props {
  accordionValue: string[];
  setAccordionValue: React.Dispatch<React.SetStateAction<string[]>>;
}

const AssigneeAccordionItem = memo(({
  accordionValue, setAccordionValue,
}: Props) => {
  const { order, onOrderDraftProcessed } = useProcessOrderContext();
  const { setGroupOrders } = useOrderContext();
  const { isLoading: isAssignLoading, assignTeamMember } = useAssignDraft();
  const { isLoading: isGroupLoading, loadOrderDrafts } = useFetchOrderDrafts({
    preventInitialFetch: true,
  });

  const groupKey = 'assignee';

  const onAssignOrder = useCallback(async (
    teamMemberId: string,
    teamMemberName: string,
    assignedComment: string,
  ) => {
    const result = await assignTeamMember({
      order,
      teamMemberId,
      teamMemberName,
      assignedComment,
    });

    setGroupOrders((_groupOrders) => _groupOrders.map((o) => (o.id === result?.id ? result : o)));

    if (result.groupId && !isZeroId(result.groupId)) {
      // Get order groups
      loadOrderDrafts(false, true, result.groupId).then((orders) => {
        setGroupOrders([result, ...(orders || [])]);
      });
    }
    onOrderDraftProcessed(result.id);
  }, [assignTeamMember, loadOrderDrafts, order, setGroupOrders, onOrderDraftProcessed]);

  const onClick = useCallback(() => {
    setAccordionValue((prev) => {
      if (prev.includes(groupKey)) {
        return prev.filter((item) => item !== groupKey);
      }

      return [...prev, groupKey];
    });
  }, [setAccordionValue]);

  return (
    <Accordion.Item value={groupKey}>
      <Accordion.Control onClick={onClick}>
        <p className="text-success-700 text-lg -semibold">
          Assignee
        </p>
      </Accordion.Control>
      <Accordion.Panel className="relative">
        <LoadingOverlay visible={isAssignLoading || isGroupLoading} />
        {
          accordionValue.includes(groupKey) && (
            <Assignee
              order={order}
              assignOrder={onAssignOrder}
              assignMe={false}
            />
          )
        }
      </Accordion.Panel>
    </Accordion.Item>
  );
});

export default AssigneeAccordionItem;
